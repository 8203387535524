<template>
  <div>
    <Message />

    <div class="my-4 text-center">
      <div class="row align-items-center justify-content-center">

        <div class="col-auto my-2 my-sm-0">
          <p class="text-white small mb-1">{{ player.playername }}</p>
          <div class="position-relative avatar-player d-inline-block playing">
            <img :src="'/images/avatars/Avatar'+player.avatar+'.png'"
              :srcset="'/images/avatars/Avatar'+player.avatar+'@2x.png 2x'" alt=""
              class="rounded-circle img-fluid avatar">

            <transition name="fade">
              <div class="circle rounded-circle border-success position-absolute overflow-hidden"
                v-show="$store.gameLogic.actualTurn == player.playerIndex">
                <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                  style="enable-background:new 0 0 24 24;" xml:space="preserve" class="position-absolute" width="24"
                  height="24"
                  v-if="$store.gameLogic.statut == 'playerTurn' || $store.gameLogic.statut == 'playerReplay'">
                  <path fill="#ff4e00" d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z" />
                  <g>
                    <circle fill="#fff" cx="6.5" cy="12.5" r="1.5" />
                    <circle fill="#fff" cx="12.5" cy="12.5" r="1.5" />
                    <circle fill="#fff" cx="18.5" cy="12.5" r="1.5" />
                  </g>
                </svg>
              </div>
            </transition>

            <Bulles :player="player" position="left" />

            <transition name="growUp">
              <div class="score position-absolute" v-show="player.score > 0">
                <div class="tooltip fade bs-tooltip-top position-absolute">
                  <div class="arrow"></div>
                  <div class="tooltip-inner small py-2">Nombre de familles complétées</div>
                </div>
                <p class="text-white bg-danger border border-white rounded px-1 mb-0">
                  {{ player.score }}
                </p>
              </div>
            </transition>

          </div>
        </div>

        <div class="col-auto position-relative">
          <!-- <div class="hand d-flex justify-content-between mt-2 mt-md-3"> -->
          <transition-group name="slideUp" tag="div" class="my-hand d-flex">
            <div class="hand-card-wrapper position-relative flex-fill" v-for="card in player.cards" :key="card">
              <div class="hand-card border-white position-absolute bg-white">
                <p class="mb-0 text-white card-letter border-white d-flex align-items-center justify-content-center position-absolute"
                  :style="'background-color:'+$deck[card].color">
                  {{ $deck[card].family[0] }}
                </p>
                <img :src="$deck[card].image" :srcset="$deck[card].image + ' 2x'" alt="" class="img-fluid-all pt-3 px-2 pb-1">
              </div>
            </div>
          </transition-group>

          <button type="button" v-if="player.cards.length > 0" @click="$store.display.mycards = true"
            class="btn btn-sm btn-info my-cards-button position-absolute text-nowrap">
            Consulter mes cartes
          </button>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <transition name="fade">
      <ModalYourTurn v-if="$store.display.modalYourTurn && player.playerIndex == $store.gameLogic.actualTurn" />
    </transition>

  </div>
</template>

<script>
  import Bulles from './Bulles.vue'
  import Message from './Message.vue'
  import ModalYourTurn from '../modals/ModalYourTurn.vue'

  export default {
    name: "MyPlayer",
    components: {
      Message,
      Bulles,
      ModalYourTurn,
    },
    props: ['player'],
    data() {
      return {
        cardID: 0,
        toPlayerID: 0,
        timeoutModal: '',
      }
    },
    methods: {
      checkGameStatus() {
        let totalScore = 0;

        Object.keys(this.$store.players).forEach((player) => {
          totalScore += this.$store.players[player].score
        })
        if (totalScore >= 7)
          return false
        return true
      }
    },
    updated() {
      if (this.$store.selfPlayer.playerIndex == this.$store.gameLogic.actualTurn && ( this.checkGameStatus() && this.$store.gameLogic.statut == 'playerTurn' || this.$store.gameLogic.statut == 'playerReplay') ) {
        document.title = "C'EST VOTRE TOUR!" + " | L'Appui - Jeu des 7 familles";

        console.log("====" + this.$store.display.modalYourTurn)

        if (this.timeoutModal) {
          clearTimeout(this.timeoutModal)
        }

        this.timeoutModal = setTimeout(() => {
          this.$store.display.modalYourTurn = true;
          let audio = new Audio('/sounds/your-turn.mp3');
          audio.volume = 0.6;
          audio.play();
        }, 20000);

      } else {
        document.title = "Partie en cours..." + " | L'Appui - Jeu des 7 familles";
        if (this.timeoutModal) {
          clearTimeout(this.timeoutModal)
        }
      }
    }
  }
</script>
