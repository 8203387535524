<template>
  <div class="modal-mask z-first-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalYourTurn = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">

            <img src="/images/pictos/ic_loading.svg" alt="" class="mb-3 spinning" width="48px" height="48px">
            <p class="font-weight-semibold mb-1 big">
              C'est votre tour!
            </p>
            <p class="text-muted mb-4">
              Les autres joueurs vous attendent, ne tardez pas à jouer!<br>Sans action de votre part, vous allez jouer automatiquement.
            </p>
            <button type="button" class="btn btn-sm btn-primary btn-block mt-3" @click="closeAll">
              Retourner sur le jeu
            </button>
          </div>
          <button type="button" class="btn btn-sm btn-light btn-block mt-3" @click="$store.display.modalYourTurn = false">
            Fermer cette fenêtre
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalYourTurn',
  data() {
    return {
      cardID: 0,
      toPlayerID: 0,
      timeoutPlay: '',
    }
  },
  methods: {
    closeAll() {
      for( let display in this.$store.display ) {
        this.$store.display[display] = false
      }
    },
    selectRngPlayer: function (p, userID) {
      let mostCardsPlayer = ''
      for (const key in p) {
        if (p[key].playerID != userID && p[key].cards.length) {
          if (!mostCardsPlayer || p[key].cards.length > p[mostCardsPlayer].cards.length)
            mostCardsPlayer = key
        }
      }
      return mostCardsPlayer
    },
    selectRngCard: function (player) {
      let familly = {
        [1]: [], [2]: [], [3]: [], [4]: [], [5]: [], [6]: [], [7]: [],
      }
      const deck = player.cards

      for (let i = 0; deck[i]; i++) {
        if (deck[i] >= 0 && deck[i] <= 5)
          familly[1].push(deck[i])
        else if (deck[i] >= 6 && deck[i] <= 11)
          familly[2].push(deck[i])
        else if (deck[i] >= 12 && deck[i] <= 17)
          familly[3].push(deck[i])
        else if (deck[i] >= 18 && deck[i] <= 23)
          familly[4].push(deck[i])
        else if (deck[i] >= 24 && deck[i] <= 29)
          familly[5].push(deck[i])
        else if (deck[i] >= 30 && deck[i] <= 35)
          familly[6].push(deck[i])
        else if (deck[i] >= 36 && deck[i] <= 41)
          familly[7].push(deck[i])
      }

      let bestChoice = {
        key: '',
        value: []
      };

      for (const key in familly) {
        if (familly[key].length > bestChoice.value.length) {
          bestChoice.value = familly[key]
          bestChoice.key = key
        }
      }
      return this.getNotOwnedCard(bestChoice.value, this.rangeCards(bestChoice.key))
      // ici on retourne une carte aléatoire dans une famille casi complete
    },
    rangeCards: function (index) {
      if (index == 1)
        return { start: 0, end: 5 }
      else if (index == 2)
        return { start: 6, end: 11 }
      else if (index == 3)
        return { start: 12, end: 17 }
      else if (index == 4)
        return { start: 18, end: 23 }
      else if (index == 5)
        return { start: 24, end: 29 }
      else if (index == 6)
        return { start: 30, end: 35 }
      else if (index == 7)
        return { start: 36, end: 41 }
      return undefined
    },
    getNotOwnedCard: function (cards, range) {
      // cette fonction décide aléatoirement d'une carte dans la famille la plus proche
      if (!range)
        return undefined
      const choiceList = []
      for (let i = range.start; i <= range.end; i++) {
        if (cards.indexOf(i) == -1)
          choiceList.push(i)
      }
      return choiceList[Math.floor(Math.random() * Math.floor(choiceList.length))]
    },
    askCard() {
      console.log("my cards: ", this.$store.selfPlayer.cards)

      if (this.timeoutPlay) {
        clearTimeout(this.timeoutPlay)
        console.log('=== timeout canceled ====')
      }

      console.log("cardID:", this.cardID, "toPlayerID:", this.toPlayerID);
      setTimeout(() => {
        if (this.cardID !== undefined) {
          this.$store.gameLogic.action.fromPlayerID = this.$store.selfPlayer.playerID
          this.$store.gameLogic.action.toPlayerID = this.toPlayerID
          this.$store.gameLogic.action.cardID = this.cardID
          this.$store.gameLogic.action.cardName = this.$deck[this.cardID].name
          this.$store.gameLogic.action.cardFamily = this.$deck[this.cardID].family
          this.$store.gameLogic.statut = 'youAskTo';
        }
        else if (this.$store.gameLogic.deck)
          this.$store.gameLogic.statut = 'pickCard'
        else
          this.$store.gameLogic.statut = 'emptyHand'

        this.$store.socket.emit('card:ask', {
          cardID: this.cardID,
          playerID: this.toPlayerID,
        });
      }, 250);
    }
  },
  mounted() {
    if (this.$store.selfPlayer.playerIndex == this.$store.gameLogic.actualTurn && !this.$store.display.gameOver) {

      console.log('+++++++ start Play timeout +++++++')

      if (this.timeoutPlay) {
        clearTimeout(this.timeoutPlay)
      }

      this.timeoutPlay = setTimeout(() => {
        if (this.$store.selfPlayer.cards.length) {
          this.cardID = this.selectRngCard(this.$store.selfPlayer)
          this.toPlayerID = this.selectRngPlayer(this.$store.players, this.$store.socket.sid)
          this.askCard()
        }
        else {
          this.cardID = undefined
          this.askCard()
        }
        this.$store.display.modalYourTurn = false;
      }, 10000);
    }
  },
  beforeDestroy() {
      console.log('+++++++ STOP Play timeout +++++++')

      if (this.timeoutPlay) {
        clearTimeout(this.timeoutPlay)
      }
  }
}
</script>
