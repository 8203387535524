<template>
  <div class="modal-mask bg-dark-95">
    <div class="modal-wrapper">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">

            <img src="/images/pictos/ic_game_deleted.svg" alt="Icone partie supprimée Appui 7 familles" class="mb-3" width="48.08px" height="49.98px">
            <p class="font-weight-semibold mb-1 big">
              La partie a été supprimée
            </p>
            <p class="text-muted mb-4">
              Cette partie n'existe plus. Vous pouvez en créer une nouvelle pour rejouer!
            </p>
            <a href="/" class="btn btn-sm btn-primary btn-block mt-3" @click="$store.display.modalGameDeleted = false">
              Créer une nouvelle partie
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalGameDeleted',
}
</script>
