<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalGameRestarted = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">

            <img src="/images/pictos/ic_join_game.svg" alt="" class="mb-3">
            <p class="font-weight-semibold mb-1 big">
              De retour dans le jeu!
            </p>
            <p class="text-muted mb-4">
              Vous reprenez la partie en cours
            </p>
            <button type="button" class="btn btn-sm btn-primary btn-block mt-3" @click="$store.display.modalGameRestarted = false">
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalGameRestarted',
}
</script>
