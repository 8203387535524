<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalPlayerLeave = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">
            <div class="mb-3">
              <img :src="'/images/avatars/Avatar'+$store.players[$store.display.modalPlayerLeave].avatar+'.png'" :srcset="'/images/avatars/Avatar'+$store.players[$store.display.modalPlayerLeave].avatar+'@2x.png 2x'" alt=""
            class="rounded-circle img-fluid avatar">
              <img src="/images/pictos/ic_arrow_right.svg" alt="" class="position-relative" style="left:-10px">
            </div>
            <p class="font-weight-semibold mb-1 big">
              {{ $store.players[$store.display.modalPlayerLeave].playername }} à quitté la partie
            </p>
            <p class="text-muted mb-4">
              Ce joueur est remplacé par l'ordinateur
            </p>
            <button type="button" class="btn btn-sm btn-primary btn-block mt-3" @click="$store.display.modalPlayerLeave = false">
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import io from 'socket.io-client'

  export default {
    name: 'ModalPlayerLeave',
    // props: ['roomID'],
    data() {
      return {
        playername: "test"
      }
    },
  }
</script>
