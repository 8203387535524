<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalWaitOthers = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">
            <img src="/images/pictos/ic_loading.svg" class="mb-3 spinning">
            <p class="font-weight-semibold mb-1 big">
              Tous les joueurs ne sont pas encore arrivés!
            </p>
            <p class="text-muted mb-3">
              Si vous lancez la partie maintenant, les joueurs manquants ne pourront pas embarquer dans la partie avec
              vous et seront remplacés par des robots. Souhaitez-vous tout de même lancer la partie?
            </p>

            <button type="button"
              class="btn btn-sm btn-primary btn-block mb-4 d-flex align-items-center justify-content-center"
              @click="startGame">
              <img src="/images/pictos/ic_cards.svg" class="mr-2">
              Lancer la partie
            </button>

            <p class="text-muted mb-3">
              Pour patienter, n’hésitez pas à consulter les règles du jeu ou le tableau des familles
            </p>

            <button type="button"
              class="btn btn-sm btn-info btn-block mb-3 d-flex align-items-center justify-content-center"
              @click="$store.display.rules = true">
              <img src="/images/pictos/ic_rules.svg" class="mr-2">
              Règles du jeu
            </button>

            <button type="button"
              class="btn btn-sm btn-info btn-block mb-0 d-flex align-items-center justify-content-center"
              @click="$store.display.families = true">
              <img src="/images/pictos/ic_familles.svg" class="mr-2">
              Tableau des familles
            </button>
          </div>
          <!-- <div class="modal-content"> -->
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> -->

          <button type="button" class="btn btn-sm btn-light btn-block mt-3"
            @click="$store.display.modalWaitOthers = false">Attendre tout le monde</button>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalWaitOthers',
    data() {
      return {
        started: ''
      }
    },
    methods: {
      startGame: function () {
        this.$gtm.trackEvent({event:'parties-commencees'})
        this.$store.socket.emit('game:start');

      }
    },
    created() {
      const started = localStorage.getItem('started');

      if (started == 'true')
        this.startGame()
    }
  }
</script>
