<template>
  <div v-show="showlogin">

    <transition name="fade" mode="out-in">
      <LoginForm v-if="!logged" :logged.sync="logged" />
      <WaitingRoom v-else />
    </transition>

    <transition name="fadeSlow">
      <SplashScreen v-if="showSplash" />
    </transition>

  </div>
</template>

<script>
  import LoginForm from './subcomponents/LoginForm.vue'
  import WaitingRoom from './subcomponents/WaitingRoom.vue'
  import SplashScreen from './subcomponents/SplashScreen.vue'

  export default {
    name: 'Login',
    components: {
      LoginForm,
      WaitingRoom,
      SplashScreen,
    },
    data() {
      return {
        logged: false,
        showSplash: true,
        // players: [],
        // for debug only //
        myPlayerLayout: this.$store.selfPlayer.myPlayerLayout,
        // end for debug only //
        // socket: this.$store.socket,
        showlogin: true
      }
    },
    methods: {
      initDisplay() {
        this.$store.display.modalGameOver = false
        if (this.$store.gameLogic.statut == 'playerReplay')
          this.$store.gameLogic.statut = 'playerTurn'
      }
    },
    mounted() {

      setTimeout(() => {
        this.showSplash = false;
      }, 2500);

      this.$store.socket.on('room:newplayer', data => {

        Object.keys(data.players).forEach((player) => {
          this.$store.gameLogic.playerOrder[data.players[player].playerIndex] = data.players[player].playerID
        });
        this.$store.players = data.players
        this.$store.nbPlayers = Object.keys(this.$store.players).length
      });

      this.$store.socket.on('game:started', data => {
        // init start or restart
        this.initDisplay()

        Object.keys(data.gameRoom).forEach((player) => {
          this.$store.gameLogic.playerOrder[data.gameRoom[player].playerIndex] = data.gameRoom[player].playerID
        });
        this.$store.players = data.gameRoom

        Object.keys(this.$store.players).map((objectKey) => {
          this.$store.players[objectKey].score = data.gameRoom[objectKey].score
          if (this.$store.selfPlayer.playerID == objectKey)
            this.$store.selfPlayer.score = data.gameRoom[objectKey].score
        });


        this.$store.nbPlayers = Object.keys(this.$store.players).length

        console.log('début partie', data)
        this.$store.gameLogic.deck = data.deck;
        this.$store.gameLogic.actualTurn = data.firstPlayer;
        if (this.$route.path == '/')
          this.$router.push(data.url)
        this.$store.socket.emit('card:getCards', data => {
          this.$store.selfPlayer.cards = []
          for (let i = 0; i < data.cards.length; i++)
            this.$store.selfPlayer.cards.push(data.cards[i])
        })
      });
    },
    created() {
      const started = localStorage.getItem('started')

      if (this.$route.query.join && started == 'true') {
        this.showlogin = false
        console.log('game restarted')
        this.$store.display.modalGameRestarted = true
      }
    }
  }
</script>
