<template>
  <div class="position-fixed z-top h-100 w-100 bg-dark ask-card overflow-scroll">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12 mx-auto">
          <Header />
        </div>
        <div class="col-12 mx-auto mb-4">
          <div class="row tabletop-wrapper bg-info p-1">
            <div class="w-100 tabletop bg-dark border border-secondary p-4 position-relative">
              <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center mx-auto mb-4"
                @click="$store.gameLogic.statut = 'playerTurn'">
                <img src="/images/pictos/ic_close.svg" class="mr-2" width="18px" height="18px">
                Annuler
              </button>

              <div class="row">
                <div class="col-12 col-md-9 col-lg-7 col-xl-6 mx-auto">
                  <div v-for="(fam, index) in families" :key="index" class="bg-white rounded mb-3 position-relative">
                    <div class="d-flex align-items-center p-3 family-dropdown rounded" :class="{'opened' : display == fam.name}" @click="display = display == fam.name ? null : fam.name">
                      <p class="mb-0 px-2 py-1 text-white rounded" :style="'background-color:'+fam.color">{{ fam.name[0] }}</p>
                      <p class="mb-0 ml-2 font-weight-semibold">Famille {{ fam.name }} ({{ Object.keys(fam.cards).length }})</p>
                      <img src="/images/pictos/ic_plus.svg" class="ml-auto plus">
                    </div>

                    <transition name="expandDown">
                      <div class="px-3 pb-3 pt-0 rounded-bottom bg-white position-absolute w-100 cards-list" v-show="display == fam.name">
                        <button v-for="(card, index) in fam.cards" :key="index"
                          class="btn btn-outline-dark btn-block text-left mt-2 px-3 py-2 border-dark font-weight-semibold"
                          @click="askCard(index)">
                          {{ card.name }}
                        </button>
                      </div>
                    </transition>
                  </div>

                  <div class="rounded bg-white text-center p-3 my-5">
                    <p class="mb-0 font-weight-semibold message">
                      Quelle carte souhaitez-vous demander à <span class="text-primary">{{ $store.gameLogic.askToPlayer.playername }}</span>?
                    </p>
                  </div>

                  <div class="row align-items-center justify-content-center my-4">
                    <div class="col-auto my-2 my-sm-0">
                      <p class="text-white small mb-1 text-center">{{ $store.selfPlayer.playername }}</p>
                      <div class="position-relative avatar-player d-inline-block playing">
                        <img :src="'/images/avatars/Avatar'+$store.selfPlayer.avatar+'.png'"
                          :srcset="'/images/avatars/Avatar'+$store.selfPlayer.avatar+'@2x.png 2x'" alt=""
                          class="rounded-circle img-fluid avatar">

                        <div class="circle rounded-circle border-success position-absolute overflow-hidden">
                          <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                            style="enable-background:new 0 0 24 24;" xml:space="preserve" class="position-absolute" width="24"
                            height="24">
                            <path fill="#ff4e00" d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z" />
                            <g>
                              <circle fill="#fff" cx="6.5" cy="12.5" r="1.5" />
                              <circle fill="#fff" cx="12.5" cy="12.5" r="1.5" />
                              <circle fill="#fff" cx="18.5" cy="12.5" r="1.5" />
                            </g>
                          </svg>
                        </div>

                        <p class="score text-white bg-danger border border-white rounded position-absolute px-1 mb-0"
                          v-show="$store.selfPlayer.score > 0">
                          {{ $store.selfPlayer.score }}
                        </p>

                      </div>

                    </div>

                    <div class="col-auto position-relative">
                      <!-- <div class="hand d-flex justify-content-between mt-2 mt-md-3"> -->
                        <div class="my-hand d-flex justify-content-between">
                          <div class="hand-card-wrapper position-relative" v-for="card in $store.selfPlayer.cards" :key="card">
                            <div class="hand-card border-white bg-white position-absolute text-center">
                              <p class="mb-0 text-white card-letter border-white d-flex align-items-center justify-content-center position-absolute"
                                :style="'background-color:'+$deck[card].color">
                                {{ $deck[card].family[0] }}
                              </p>
                              <img :src="$deck[card].image" :srcset="$deck[card].image + ' 2x'" alt="" class="img-fluid-all pt-3 px-2 pb-1">
                            </div>
                          </div>
                        </div>
                      <button type="button" @click="$store.display.mycards = true"
                        class="btn btn-sm btn-info my-cards-button position-absolute text-nowrap">
                        Consulter mes cartes
                      </button>
                      <!-- </div> -->
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../subcomponents/Header.vue'
  export default {
    name: "AskCard",
    components:{
      Header
    },
    data() {
      return {
        families: {},
        cardsToAsk: {},
        display: null,
        cardID: null,
        deck: this.$deck,
        cards: this.$store.selfPlayer.cards,
      }
    },
    beforeMount() {

      for (let cardIndex of this.cards) {
        if (!this.families[this.$deck[cardIndex].family]) {
          this.families[this.$deck[cardIndex].family] = {};
          this.families[this.$deck[cardIndex].family].name = this.$deck[cardIndex].family;
          this.families[this.$deck[cardIndex].family].color = this.$deck[cardIndex].color;
          this.families[this.$deck[cardIndex].family].cards = {};
        }
      }
      // on peuple Families avec toutes les cartes que l'on ne possède pas
      this.$deck.forEach((card, index) => {
        if (!this.cards.includes(index) && this.families[card.family]) {
          this.families[card.family].cards[index] = card
        }
      })

    },
    methods: {
      close() {
        this.$emit('closeAskCard', true);
        console.log('close')
      },
      askCard(cardID) {
        this.cardID = cardID;
        // if (this.timeout) {
        //   clearTimeout(this.timeout)
        //   console.log('=== timeout canceled ====')
        // }
        console.log("cardID:", this.cardID, "toPlayerID:", this.$store.gameLogic.askToPlayer.playerID);

        if (this.cardID !== undefined) {
          this.$store.gameLogic.action.fromPlayerID = this.$store.selfPlayer.playerID
          this.$store.gameLogic.action.toPlayerID = this.$store.gameLogic.askToPlayer.playerID
          this.$store.gameLogic.action.cardID = this.cardID
          this.$store.gameLogic.action.cardName = this.$deck[this.cardID].name
          this.$store.gameLogic.action.cardFamily = this.$deck[this.cardID].family
          this.$store.gameLogic.statut = 'youAskTo';
        }
        else if (this.$store.gameLogic.deck)
          this.$store.gameLogic.statut = 'pickCard'
        else
          this.$store.gameLogic.statut = 'emptyHand'

        console.log('ASK')

        this.$store.socket.emit('card:ask', {
          cardID: this.cardID,
          playerID: this.$store.gameLogic.askToPlayer.playerID,
        });
        //  a afficher :
        // Vous demandez la mère de la famille Aubart…
      },
    }
  }
</script>
