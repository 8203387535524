<template>
  <div v-if="started == 'false'">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12 col-lg-9 col-xl-8 mx-auto">
          <Header />
        </div>
        <div class="col-12 col-lg-9 col-xl-8 mx-auto">
          <div class="row tabletop-wrapper bg-info p-1">
            <div class="tabletop bg-pattern border border-secondary p-4 w-100">
              <div class="row align-items-center">
                <div class="col-12 text-center" v-if="$store.gameLogic.playerLayout[2]">
                  <WaitingPlayer :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[2]]]" />
                </div>
                <div class="col-2 col-md-3">
                  <div class="row h-100">
                    <div class="col-12 align-items-center d-flex justify-content-center px-0"
                      v-if="$store.gameLogic.playerLayout[1]">
                      <WaitingPlayer :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[1]]]" />
                    </div>
                    <div class="col-12 align-items-center d-flex justify-content-center px-0"
                      v-if="$store.gameLogic.playerLayout[0]">
                      <WaitingPlayer :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[0]]]" />
                    </div>
                  </div>
                </div>
                <div class="col-8 col-md-6">
                  <div class="rounded bg-white p-4 text-center mb-3">
                    <div v-if="$store.nbPlayers < $store.gameLogic.playerLimit && !$store.gameLogic.onlyBot">
                      <img src="/images/pictos/ic_loading.svg" width="24.58px" height="24px" class="spinning" alt="Icone attente autres joueurs Appui 7 familles">
                      <p class="mb-0 font-weight-semibold mt-2">
                        En attente de joueurs...
                      </p>
                    </div>
                    <div v-else>
                      <img src="/images/pictos/ic_check.svg" alt="Icone joueurs prêts Appui 7 familles" width="24px" height="24px">
                      <p class="mb-0 font-weight-semibold mt-2">
                        Tous les joueurs sont arrivés
                      </p>
                    </div>
                  </div>
                  <button type="button"
                    class="btn btn-secondary btn-block mb-3 d-flex align-items-center justify-content-center text-white"
                    v-if="this.$store.nbPlayers < $store.gameLogic.playerLimit && !$store.gameLogic.onlyBot"
                    @click="$store.display.modalShare = true">
                    <img src="/images/pictos/ic_invite.svg" alt="Icone partager partie 7 familles Appui" class="mr-2 d-none d-sm-block" width="25.7px" height="17.98px">
                    Inviter des amis
                  </button>
                  <button type="button"
                    class="btn btn-lg btn-primary btn-block mb-0 d-flex align-items-center justify-content-center"
                    @click="startGame">
                    <img src="/images/pictos/ic_computer.svg" alt="Icone lancer partie 7 familles Appui" class="mr-2 d-none d-sm-block" width="39.5px" height="31.98px">
                    Lancer la partie
                  </button>
                </div>
                <div class="col-2 col-md-3">
                  <div class="row h-100">
                    <div class="col-12 align-items-center d-flex justify-content-center px-0"
                      v-if="$store.gameLogic.playerLayout[3]">
                      <WaitingPlayer :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[3]]]" />
                    </div>
                    <div class="col-12 align-items-center d-flex justify-content-center px-0"
                      v-if="$store.gameLogic.playerLayout[4]">
                      <WaitingPlayer :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[4]]]" />
                    </div>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <WaitingPlayer :player="$store.selfPlayer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <transition name="fade">
      <ModalShare :roomID="$store.roomID" v-show="$store.display.modalShare" />
    </transition>

    <transition name="fade">
      <ModalWaitOthers v-show="$store.display.modalWaitOthers" />
    </transition>

  </div>
</template>

<script>
  import Header from './Header.vue'
  import WaitingPlayer from '../gameUI/WaitingPlayer.vue'
  import ModalWaitOthers from '../modals/ModalWaitOthers.vue'
  import ModalShare from '../modals/ModalShare.vue'

  export default {
    name: 'WaitingRoom',
    components: {
      WaitingPlayer,
      Header,
      ModalWaitOthers,
      ModalShare,
    },
    data() {
      return {
        store: this.$store,
        myPlayerLayout: this.$store.selfPlayer.myPlayerLayout,
        started: true
      }
    },
    created() {
      if (!(this.$route.query.join || this.$store.gameLogic.onlyBot)) {
        this.$store.display.modalShare = true;
      }
      this.started = localStorage.getItem('started');

      if (this.started == 'true') {
        this.startGame()
      }
    },
    methods: {
      startGame: function () {
        if (this.$store.gameLogic.playerLimit != this.$store.nbPlayers && !this.$store.gameLogic.onlyBot) {
          this.$store.display.modalWaitOthers = true
        } else {
          this.$gtm.trackEvent({event:'parties-commencees'})
          this.$store.socket.emit('game:start');
        }
      }
    }
  }
</script>
