import $store from './stores/dataStore'
import $deck from './stores/deckStore'


function startListener() {
    $store.socket.on('card:answer', data => {


        if (data.newCard === 0 || data.newCard) {
            console.log('Nouvelle carte id: ' + data.newCard)
            console.log($store.selfPlayer.cards.length)
            setTimeout(() => {
                console.log($store.selfPlayer.cards.length)
            }, 8000);
        }

        // Carte demandé trouvé =>
        if (data.hasCard) {
            console.log('trouvé')
            $store.gameLogic.statut = 'playerAskTo';
            new Audio('/sounds/bulle.mp3').play();
            setTimeout(() => {
                $store.gameLogic.action.cardID = data.newCard
                $store.gameLogic.action.cardName = $deck[data.newCard].name
                $store.gameLogic.action.cardFamily = $deck[data.newCard].family

                if (data.hasPicked) {
                    $store.gameLogic.statut = 'playerDraw';
                    new Audio('/sounds/draw.mp3').play();
                    setTimeout(() => {
                        $store.gameLogic.deck = data.newDeck
                        new Audio('/sounds/card-sound.mp3').play();
                    }, 1500)

                    setTimeout(() => {
                        $store.selfPlayer.cards.push(data.newCard)
                        $store.selfPlayer.cards.sort((a, b) => a - b)
                        console.log('bonne carte pioché')
                        $store.gameLogic.statut = 'goodPick';
                        new Audio('/sounds/found-card.mp3').play();

                        setTimeout(() => {
                            if (!data.newFamilly)
                                $store.gameLogic.statut = 'playerReplay';
                            else {
                                $store.selfPlayer.score += 1;
                                $store.players[$store.selfPlayer.playerID].score += 1;
                                famillyRemove(data, $store)
                            }
                        }, 2500);
                    }, 2500);
                }
                else {
                    $store.players[data.toPlayerIndex].cards.splice(0, 1)
                    $store.gameLogic.statut = 'playerNewCard';
                    $store.selfPlayer.cards.push(data.newCard)
                    $store.selfPlayer.cards.sort((a, b) => a - b)
                    new Audio('/sounds/found-card.mp3').play();

                    setTimeout(() => {
                        if (!data.newFamilly) {
                            $store.gameLogic.statut = 'playerReplay';
                        }
                        else {
                            $store.selfPlayer.score += 1;
                            $store.players[$store.selfPlayer.playerID].score += 1;
                            famillyRemove(data, $store)
                        }
                    }, 2500);
                }
            }, 2500);

        }// Carte demandé non trouvé =>
        else if (!data.hasCard) {
            if (data.toPlayerIndex) {
                $store.gameLogic.statut = 'playerAskTo';
                new Audio('/sounds/bulle.mp3').play();
            }
            else {
                $store.gameLogic.statut = 'playerPickCard';
            }

            setTimeout(() => {

                if (data.hasPicked) {
                    if (data.toPlayerIndex) {
                        $store.gameLogic.statut = 'playerDraw';
                        new Audio('/sounds/draw.mp3').play();
                        console.log('pioche');
                        setTimeout(() => {
                            new Audio('/sounds/card-sound.mp3').play();
                            $store.gameLogic.deck = data.newDeck
                        },1300);
                    }
                    setTimeout(() => {
                        $store.gameLogic.statut = 'newCard';
                        $store.gameLogic.action.cardID = data.newCard
                        $store.gameLogic.action.cardName = $deck[data.newCard].name
                        $store.gameLogic.action.cardFamily = $deck[data.newCard].family

                        setTimeout(() => {
                            $store.selfPlayer.cards.push(data.newCard)
                            $store.selfPlayer.cards.sort((a, b) => a - b)
                        }, 2500);

                        setTimeout(() => {
                            if (!data.newFamilly) {
                                $store.gameLogic.statut = 'playerTurn';
                                $store.gameLogic.actualTurn = data.nextPlayer
                            } else {
                                $store.selfPlayer.score += 1;
                                $store.players[$store.selfPlayer.playerID].score += 1;
                                famillyRemove(data, $store)
                            }
                        }, 2500)
                    }, 1700)
                }
                else {
                    $store.gameLogic.statut = 'emptyDraw';
                    new Audio('/sounds/draw.mp3').play();
                    setTimeout(() => {
                        $store.gameLogic.actualTurn = data.nextPlayer
                        $store.gameLogic.statut = 'playerTurn';
                    }, 2500)
                }
            }, 2500);


        }// Plus de carte dans le deck
    });

    function famillyRemove(data, $store) {
        for (let i = 0; i < data.newFamilly.length; i++) {
            let removedCard = $store.selfPlayer.cards.indexOf(data.newFamilly[i])
            $store.selfPlayer.cards.splice(removedCard, 1)
            $store.gameLogic.statut = 'playerFamily';
            let audio = new Audio('/sounds/famille.mp3');
            audio.volume = 0.4;
            audio.play();
            console.log('nouvelle carte pioché')
            setTimeout(() => {
                $store.gameLogic.statut = 'playerReplay';
            }, 2500);
        }
    }

    $store.socket.on('card:action', data => {
        $store.gameLogic.action.fromPlayerID = data.fromPlayerIndex

        if (data.toPlayerIndex) {
            $store.gameLogic.action.cardID = data.cardID
            if (data.cardID) {
                $store.gameLogic.action.cardName = $deck[data.cardID].name
                $store.gameLogic.action.cardFamily = $deck[data.cardID].family
            }
            $store.gameLogic.action.toPlayerID = data.toPlayerIndex

            $store.gameLogic.statut = 'playerAskTo';
            new Audio('/sounds/bulle.mp3').play();

            setTimeout(() => {
                if (data.hasCard && !data.hasPicked && data.cardID >= 0) { // si la carte demandé a été recu du joueur
                    $store.gameLogic.statut = 'playerNewCard';
                    new Audio('/sounds/found-card.mp3').play();
                    $store.players[data.toPlayerIndex].cards.splice(0, 1)
                    $store.players[data.fromPlayerIndex].cards.push(true)
                    if ($store.socket.sid == data.toPlayerIndex) {
                        let removedCard = $store.selfPlayer.cards.indexOf(data.cardID)
                        $store.selfPlayer.cards.splice(removedCard, 1)
                    }


                    if (data.newFamilly) { // si une famille a été complété
                        setTimeout(() => {
                            $store.players[data.fromPlayerIndex].cards.splice(0, 6)
                            $store.gameLogic.statut = 'playerFamily';
                            let audio = new Audio('/sounds/famille.mp3');
                            audio.volume = 0.4;
                            audio.play();
                            $store.players[data.fromPlayerIndex].score += 1;
                            setTimeout(() => {
                                $store.gameLogic.statut = 'playerReplay';
                            }, 2500)
                        }, 2500)
                    } else {
                        setTimeout(() => {
                            $store.gameLogic.statut = 'playerReplay';
                        }, 2500)
                    }
                    // ici verrifier si la cible c'est nous, afin de retiré la carte perdu de notre main
                    // verrifier aussi si nous sommes l'auteur de l'action pour nous ajouter la carte en cas d'autoplay
                }
                else if (data.hasPicked) { // sinon le joueur pioche une carte
                    $store.gameLogic.statut = 'playerDraw';
                    new Audio('/sounds/draw.mp3').play();

                    setTimeout(() => {
                        new Audio('/sounds/card-sound.mp3').play();
                        $store.gameLogic.deck = data.newDeck
                        console.log("nouvelle carte pioché")
                        $store.players[data.fromPlayerIndex].cards.push(true)
                    },1500);


                    // bonne pioche mais via la pioche
                    if (data.hasCard) {
                        setTimeout(() => {
                            $store.gameLogic.statut = 'goodPick';

                            if (data.newFamilly) { // si une famille a été complété
                                setTimeout(() => {
                                    $store.players[data.fromPlayerIndex].cards.splice(0, 6)
                                    $store.gameLogic.statut = 'playerFamily';
                                    let audio = new Audio('/sounds/famille.mp3');
                                    audio.volume = 0.4;
                                    audio.play();
                                    $store.players[data.fromPlayerIndex].score += 1;
                                    setTimeout(() => {
                                        $store.gameLogic.statut = 'playerReplay';
                                    }, 2500)
                                }, 2500)
                            } else {
                                setTimeout(() => {
                                    $store.gameLogic.statut = 'playerReplay';
                                }, 2500)
                            }
                        }, 2500)
                    }
                }
                else {
                    $store.gameLogic.statut = 'emptyDraw';
                    new Audio('/sounds/draw.mp3').play();
                    setTimeout(() => {
                        $store.gameLogic.actualTurn = data.nextPlayer
                        $store.gameLogic.statut = 'playerTurn';
                    }, 2500)
                }

                // $store.gameLogic.deck = data.newDeck

                setTimeout(() => {
                    if ($store.gameLogic.statut != 'emptyDraw' && $store.gameLogic.actualTurn != data.nextPlayer) {
                        $store.gameLogic.actualTurn = data.nextPlayer
                        $store.gameLogic.statut = 'playerTurn';
                    }
                }, 2500)

            }, 2500);
        }
        else if (data.hasPicked) {
            $store.gameLogic.statut = 'playerPickCard'
            new Audio('/sounds/card-sound.mp3').play();
            setTimeout(() => {

                $store.gameLogic.actualTurn = data.nextPlayer
                $store.players[data.fromPlayerIndex].cards.push(true)


                if (data.hasCard) {
                    $store.gameLogic.statut = 'goodPick';
                    setTimeout(() => {
                        $store.gameLogic.statut = 'playerReplay';
                    }, 2500)
                }
                else {
                    $store.gameLogic.statut = 'playerTurn';
                }

                if (data.newFamilly) { // si une famille a été complété
                    $store.players[data.fromPlayerIndex].cards.splice(0, 6)
                    $store.gameLogic.statut = 'playerFamily';
                    let audio = new Audio('/sounds/famille.mp3');
                    audio.volume = 0.4;
                    audio.play();
                    $store.players[data.fromPlayerIndex].score += 1;
                }

            }, 2500)
        }


        // data.hasCard
    });

    // a déplacer dans un fichier a part

    $store.socket.on('game:finish', () => {
        setTimeout(() => {
            $store.display.modalGameOver = true;
        }, 5000);
    });

    $store.socket.on('room:kick', () => {
        window.location.href = '/'
    });
    $store.socket.on('room:playerLeave', (data) => {
        // si besoin d'une pop up de déconéxion
        $store.players[data.sid].bot = true // pour afficher l'emoji robot

        $store.display.modalPlayerLeave = data.sid;
        console.log('joueur ' + $store.players[data.sid].playername + " s'est déconnécté.")
    });
    $store.socket.on('ping', () => {
        console.log("PING")
        $store.socket.emit('game:pong')
    });


}

setTimeout(() => {
    startListener()
}, 0);
