<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded">
            <div class="container-fluid py-4 text-center">
              <div class="row">
                <div class="col-12 text-center mb-4">
                  <img src="/images/gameover.png" srcset="/images/gameover@2x.png 2x" alt="">
                </div>
              </div>
              <div class="row m-0">
                <div class="col-auto pl-2">
                  <p class="font-weight-semibold small">Joueurs</p>
                </div>
                <div class="col-auto ml-auto pr-4">
                  <p class="font-weight-semibold small">Familles composées</p>
                </div>
              </div>

              <div v-for="(player,key) in playersSorted" :key="player.playerID">
                <div class="row m-0 py-2 rounded-pill align-items-center"
                  :class="{ 'bg-yellow-light mb-2' : key == 0 }">
                  <div class="col-auto px-2">
                    <img :src="'/images/avatars/Avatar'+player.avatar+'.png'"
                      :srcset="'/images/avatars/Avatar'+player.avatar+'@2x.png 2x'" alt=""
                      class="rounded-circle img-fluid avatar" width="50px">
                  </div>
                  <div class="col px-2">
                    <p class="mb-0 font-weight-semibold text-yellow text-left" :class="[ key == 0 ? 'text-yellow' : 'text-muted' ]">
                      <span v-if="key == 0">Bravo, {{ player.playername }} a gagné!</span>
                      <span v-else>{{ player.playername }}</span>
                    </p>
                  </div>
                  <div class="col-auto ml-auto pr-4" v-if="player.score > 0">
                    <p class="mb-0 rounded-sm border border-white bg-danger text-white px-2 py-1 shadow">
                      {{ player.score }}
                    </p>
                  </div>
                </div>
                <hr class="border-muted m-2" v-if="key != 0 && key != Object.keys($store.players).length - 1">
              </div>

            </div>
            <div class="bg-primary-light px-3 py-4 rounded-bottom">
              <p class="text-center px-sm-5 mb-4">Merci d’avoir joué au jeu des 7 familles! Si vous vous êtes retrouvé(e) dans l'une de ces familles, n'hésitez pas à contacter Info-aidant.</p>
              <a href="tel:1 855 852-7784"
                @click="triggerCall"
                class="btn btn-sm btn-light btn-block d-flex align-items-center justify-content-center">
                <img src="/images/pictos/ic_phone.svg" class="mr-2">
                1 855 852-7784
              </a>
              <button type="button" @click="$store.display.modalAppui = true"
                class="btn btn-sm btn-light btn-block mt-3 d-flex align-items-center justify-content-center">
                <img src="/images/pictos/ic_external_link.svg" class="mr-2">
                lappui.org
              </button>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-block mt-3" @click="restart">Jouer de nouveau avec les mêmes
            joueurs</button>
          <a href="/"
            class="btn btn-light text-primary btn-block mt-3 d-flex align-items-center justify-content-center">
            <img src="/images/pictos/ic_door.svg" class="mr-2">
            Quitter la partie
          </a>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
  export default {
    name: 'ModalGameOver',
    data() {
      return {
        playersSorted: Object.values(this.$store.players),
      }
    },
    methods: {
      restart() {
        this.$store.socket.emit('game:restart');
      },
      triggerCall() {
        this.$gtm.trackEvent({event:'telephone'})
      }
    },
    mounted() {
      this.playersSorted.sort((a, b) => (a.score > b.score) ? -1 : ((b.score > a.score) ? 1 : 0))
    }

  }
</script>
