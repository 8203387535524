<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalShare = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">
            <img src="/images/pictos/ic_users_black.svg" class="mb-3" width="48px" height="48px" alt="utilisateur partegr Appui 7 familles">
            <p class="font-weight-semibold mb-1 big">
              Nouvelle partie
            </p>
            <p class="text-muted mb-4">
              Partagez ce lien avec les amis avec qui vous souhaitez jouer.
            </p>

            <input type="text" readonly class="form-control text-center border-0 font-weight-semibold text-info mb-4 pl-5" id="urlSharing" v-bind:value="host + '?join=' + roomID">
            <button type="button" class="btn btn-sm btn-info btn-block d-flex align-items-center justify-content-center" @click="copyUrl">
              <img src="/images/pictos/ic_copy.svg" alt="icone copy url Appui 7 familles" class="mr-2" width="19.66px" height="23.98px">
              {{ copyBtn }}
            </button>
            <ShareNetwork
                network="sms"
                :url="host + '?join=' + roomID"
                title="Rejoignez-moi pour une partie de 7 familles!"
                description="Venez découvrir l'univers des proches aidants à travers le jeu des 7 familles de l'Appui!"
              >
                <img src="/images/pictos/ic_sms.svg" alt="Icone partage SMS Appui 7 familles" class="mr-2" width="24px" height="24px">
                Envoyer par SMS
            </ShareNetwork>
            <ShareNetwork
                network="facebook"
                :url="host + '?join=' + roomID"
                title="Rejoignez-moi pour une partie de 7 familles!"
                description="Venez découvrir l'univers des proches aidants à travers le jeu des 7 familles de l'Appui!"
              >
                <img src="/images/pictos/ic_facebook.svg" alt="Icone Facebook partage partie 7 familles Appui" class="mr-2" width="19px" height="19px">
                Partager sur Facebook
            </ShareNetwork>
            <ShareNetwork
                network="whatsapp"
                :url="host + '?join=' + roomID"
                title="Rejoignez-moi pour une partie de 7 familles!"
                description="Venez découvrir l'univers des proches aidants à travers le jeu des 7 familles de l'Appui!"
              >
                <img src="/images/pictos/ic_whatsapp.svg" alt="Icone Whatsapp partage partie 7 familles Appui" class="mr-2" width="23.88px" height="23.98px">
                Envoyer via Whatsapp
            </ShareNetwork>
          </div>
          <button type="button" class="btn btn-sm btn-light btn-block mt-3" @click="$store.display.modalShare = false">Retour</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import io from 'socket.io-client'

  export default {
    name: 'ModalShare',
    props: ['roomID'],
    data() {
      return {
        copyBtn : "Copier le lien",
        host: location.origin.toString(),
      }
    },
    methods: {
      copyUrl: function(){
        document.getElementById("urlSharing").select()
        document.execCommand('copy')
        this.copyBtn = "Lien copié!";
      },
    }
  }
</script>
