<template>
  <div>
    <div v-if="myPlayerLayout" class="container h-100">
      <div class="row h-100 pb-5">
        <div class="col-12 mx-auto">
          <Header />
        </div>
        <div class="col-12 mx-auto">
          <div class="row tabletop-wrapper bg-info p-1">
            <div class="w-100 tabletop bg-pattern p-4 position-relative">
              <div class="row align-items-center">
                <div class="col-12 text-center" v-if="$store.gameLogic.playerLayout[2]">
                  <Player position="left" :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[2]]]" />
                </div>
                <div class="col-4 col-lg">
                  <div class="row h-100">
                    <div class="col-12 align-items-center d-flex justify-content-center"
                      v-if="$store.gameLogic.playerLayout[1]">
                      <Player position="left"
                        :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[1]]]" />
                    </div>
                    <div class="col-12 align-items-center d-flex justify-content-center"
                      v-if="$store.gameLogic.playerLayout[0]">
                      <Player position="left"
                        :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[0]]]" />
                    </div>
                  </div>
                </div>
                <div class="col-4 col-lg-auto px-lg-4">
                  <Stack />
                </div>
                <div class="col-4 col-lg">
                  <div class="row h-100">
                    <div class="col-12 align-items-center d-flex justify-content-center"
                      v-if="$store.gameLogic.playerLayout[3]">
                      <Player position="right"
                        :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[3]]]" />
                    </div>
                    <div class="col-12 align-items-center d-flex justify-content-center"
                      v-if="$store.gameLogic.playerLayout[4]">
                      <Player position="right"
                        :player="$store.players[$store.gameLogic.playerOrder[myPlayerLayout[4]]]" />
                    </div>
                  </div>
                </div>
                <!-- <Message :statut="statut" /> -->
                <div class="col-12 text-center">
                  <MyPlayer :player="$store.selfPlayer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <MyCards v-show="$store.display.mycards" />
    </transition>

    <transition name="fade">
      <AskCard v-if="$store.gameLogic.statut == 'askCard'" :statut="$store.gameLogic.statut"/>
    </transition>

    <transition name="fade">
      <ModalGameOver v-if="$store.display.modalGameOver"/>
    </transition>

    <transition name="fade">
      <ModalGameRestarted v-show="$store.display.modalGameRestarted" />
    </transition>

    <transition name="fade">
      <Tutorial v-if="$store.display.tutorial"/>
    </transition>

  </div>
</template>

<script>
  import Player from './gameUI/Player.vue'
  import MyPlayer from './gameUI/MyPlayer.vue'
  import Stack from './gameUI/Stack.vue'
  import ModalGameOver from './modals/ModalGameOver.vue'
  import ModalGameRestarted from './modals/ModalGameRestarted.vue'
  import Header from './subcomponents/Header.vue'
  import AskCard from './gameUI/AskCard.vue'
  import MyCards from './gameUI/MyCards.vue'
  import Tutorial from './subcomponents/Tutorial.vue'
  import easyScroll from 'easy-scroll';


  export default {
    name: 'Game',
    components: {
      Player,
      MyPlayer,
      Header,
      Stack,
      AskCard,
      ModalGameOver,
      ModalGameRestarted,
      MyCards,
      Tutorial,
      // Message,
    },
    data() {
      return {
        store: this.$store,
        deck: this.$deck,
        myPlayerLayout: this.$store.selfPlayer.myPlayerLayout,
        showAskCard: false,
      }
    },
    created() {
      if(!sessionStorage.getItem('tutorialClosed')) {
        sessionStorage.setItem('tutorialClosed', false)
      } else if(sessionStorage.getItem('tutorialClosed') == "true") {
        // localStorage.setItem('tutorialClosed',false)
        this.$store.display.tutorial = false
      }


      localStorage.setItem('started', true)
      if (!this.$store.selfPlayer.playername) {
        const url = localStorage.getItem('lastParty');
        window.location.replace('/' + url)
      }
      //début de partie. Ici les animations de début
      setTimeout(() => {
        this.$store.gameLogic.statut = 'playerTurn';
        easyScroll({
            'scrollableDomEle': window,
            'direction': 'bottom',
            'duration': 1000,
            'easingPreset': 'easeInOutCubic',
        });
      }, 2500)

      //play audio to unlock sound
      function unlockAudio() {
        let sound = new Audio('/sounds/card-sound.mp3');
        sound.volume = 0;
        sound.play();
        document.body.removeEventListener('click', unlockAudio)
        document.body.removeEventListener('touchstart', unlockAudio)
      }
      document.body.addEventListener('click', unlockAudio, {passive: true});
      document.body.addEventListener('touchstart', unlockAudio, {passive: true});
    },
    destroyed() {
      this.$store.socket.emit('disconnect')
    }
  }
</script>
