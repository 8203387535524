<template>
  <div class="position-fixed z-first">
    <div class="position-fixed w-100 h-100 bg-dark overflow-scroll">
      <div class="bg-pattern position-fixed w-100 h-100 pointer-events-none"></div>
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-12">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto align-self-start my-4 my-md-5">
                  <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center" @click="$store.display.about = false">
                    <img src="/images/pictos/ic_close.svg" class="mr-2" alt="Icone fermer menu Appui 7 familles" width="18px" height="18px">
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 align-self-center">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto text-white mb-4 mb-md-5">
                  <h5 class="mb-4 font-weight-semibold">À propos du projet</h5>
                  <p>Les 7 familles sont autant d’occasions de <strong>montrer un vécu de proche aidant :</strong> génération sandwich, conciliation travail-famille, solitude, dégradation cyclique de l'état de santé… Suffisamment concrètes, ces situations basées sur des données statistiques visent à <strong>permettre aux personnes qui ne se savent pas proches aidantes de se reconnaître et de chercher de l’aide si elles le souhaitent.</strong> Ce jeu vise également à apporter douceur et joie aux familles concernées à travers de bons moments passés ensemble.</p>
                  <p>Ce jeu a été créé par l’<strong>Appui pour les proches aidants.</strong> Organisme sans but lucratif, l’Appui pour les proches aidants <strong>contribue à améliorer la qualité de vie des proches aidants et à faciliter leur quotidien.</strong> Chapeauté par l’Appui, <strong>Info-aidant</strong> est un service professionnel qui apporte écoute, information et références aux proches aidants. Il est confidentiel, gratuit et accessible partout au Québec afin d’accompagner un maximum de personnes. En 2019, environ 20 000 interventions ont été réalisées.</p>
                  <div class="row">
                    <div class="col-12">
                      <img src="/images/rabagliati.jpg" srcset="/images/rabagliati@2x.jpg 2x" alt="Pierre Rabagliati dessinateur Appui 7 familles" width="120px" height="180px" class="float-left mr-3 mb-3">
                      <p>Le dessinateur : <strong>Michel Rabagliati</strong><br>
                      L’Appui collabore avec Michel Rabagliati afin d’offrir un visage aux différents personnages des familles. Auteur et illustrateur québécois de bande dessinée, il est notamment connu pour sa série Paul. Ayant lui-même été proche aidant, il a abordé le thème de la proche aidance à plusieurs reprises.</p>
                    </div>
                  </div>
                  <p>
                    Promoteur : <a href="https://www.lappui.org/" @click="goToAppui" target="_blank" rel="nofollow noopener">L'Appui pour les proches aidants</a><br>
                    Illustrateur : <a href="http://michelrabagliati.com/" target="_blank" rel="nofollow noopener">Michel Rabagliati</a><br>
                    Concepteur : <a href="http://winkstrategies.com/" target="_blank" rel="nofollow noopener">Wink Stratégies</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 align-self-end bg-primary-light">
            <div class="row mx-0">
              <div class="col-12 align-self-end">
                <div class="row">
                  <div class="container py-4">
                    <div class="row">
                      <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto">
                        <p class="text-center">Si vous vous êtes retrouvé(e) dans l'une de ces familles, n'hésitez pas à contacter Info-aidant.</p>

                        <a href="tel:1 855 852-7784" @click="triggerCall" class="btn btn-sm btn-light btn-block d-flex align-items-center justify-content-center">
                          <img src="/images/pictos/ic_phone.svg" class="mr-2" alt="Téléphone Appui proche aidants" width="18px" height="18px">
                          1 855 852-7784
                        </a>
                        <button type="button" class="btn btn-sm btn-light btn-block mt-3 d-flex align-items-center justify-content-center"
                          @click="$store.display.modalAppui = true">
                          <img src="/images/pictos/ic_external_link.svg" class="mr-2" alt="Icone Lien Appui proches aidants" width="18px" height="18px">
                          lappui.org
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',
  mounted() {
    this.$gtm.trackEvent({event:'a-propos'})
  },
  methods: {
    goToAppui() {
      this.$gtm.trackEvent({event:'site'})
    },
    triggerCall() {
      this.$gtm.trackEvent({event:'telephone'})
    }
  }
}
</script>
