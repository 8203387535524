<template>
  <div id="app">

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <transition name="fade">
      <ModalPlayerLeave v-if="$store.display.modalPlayerLeave"/>
    </transition>

    <transition name="fade">
      <Rules v-if="$store.display.rules" />
    </transition>

    <transition name="fade">
      <About v-if="$store.display.about" />
    </transition>

    <transition name="fade">
      <Families v-if="$store.display.families" />
    </transition>

    <transition name="fade">
      <ModalCardDetails v-if="$store.display.modalCardDetails !== false"/>
    </transition>

    <transition name="fade">
      <ModalGameDeleted v-show="$store.display.modalGameDeleted"/>
    </transition>

    <transition name="fade">
      <ModalAppui v-show="$store.display.modalAppui"/>
    </transition>

    <transition name="fade">
      <ModalLeave v-show="$store.display.modalLeave"/>
    </transition>


  </div>
</template>

<script>
  import Rules from './components/subcomponents/Rules.vue'
  import About from './components/subcomponents/About.vue'
  import Families from './components/subcomponents/Families.vue'
  import ModalGameDeleted from './components/modals/ModalGameDeleted.vue'
  import ModalPlayerLeave from './components/modals/ModalPlayerLeave.vue'
  import ModalCardDetails from './components/modals/ModalCardDetails.vue'
  import ModalAppui from './components/modals/ModalAppui.vue'
  import ModalLeave from './components/modals/ModalLeave.vue'


  export default {
    name: 'App',
    components: {
      ModalPlayerLeave,
      ModalGameDeleted,
      ModalLeave,
      Rules,
      About,
      Families,
      ModalCardDetails,
      ModalAppui,
    },
    data() {
      return {
        store:this.$store
      }
    }
  }
</script>

<style lang="scss">
  @import "./assets/styles/_variables.scss";
  @import "../node_modules/bootstrap/scss/bootstrap.scss";

  @import "../node_modules/tiny-slider/src/tiny-slider.scss";

  @import "./assets/styles/_global.scss";
  @import "./assets/styles/_login.scss";
  @import "./assets/styles/_splashscreen.scss";
  @import "./assets/styles/_player.scss";
  @import "./assets/styles/_bulles.scss";
  @import "./assets/styles/_modals.scss";
  @import "./assets/styles/_cards.scss";
  @import "./assets/styles/_message.scss";
  @import "./assets/styles/_askcard.scss";
  @import "./assets/styles/_families.scss";
  @import "./assets/styles/_menu.scss";
  @import "./assets/styles/_transitions.scss";
  @import "./assets/styles/_tutorial.scss";
  @import "./assets/styles/_card-details.scss";
</style>
