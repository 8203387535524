<template>
  <div class="position-fixed w-100 h-100 bg-dark overflow-scroll z-first">
    <div class="bg-pattern position-fixed w-100 h-100 pointer-events-none"></div>
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12 align-self-start my-4 my-md-5">
          <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center" @click="$store.display.mycards = false">
            <img src="/images/pictos/ic_close.svg" class="mr-2" alt="Icone fermer mes cartes 7 familles Appui" width="18px" height="18px">
            Fermer
          </button>
        </div>
        <div class="col-12 mx-auto text-white">
          <h5 class="font-weight-semibold text-center">Vos cartes</h5>
          <p class="text-center mb-5">Cliquez sur une carte pour avoir plus de détails</p>
          <div class="row justify-content-center">
            <div class="col mb-5" v-for="(card, index) in $store.selfPlayer.cards" :key="index" @click="$store.display.modalCardDetails = card">
              <div class="mycard bg-white border-white mb-3 mx-auto position-relative text-center">
                <p class="h5 mb-0 text-white card-letter border-white d-flex align-items-center justify-content-center position-absolute" :style="'background-color:'+$deck[card].color">
                  {{ $deck[card].family[0] }}
                </p>
                <img :src="$deck[card].image" alt="" class="img-fluid-all pt-3 px-2 pb-1">
              </div>
              <p class="mb-1 text-center text-primary font-weight-semibold">
                Famille {{ $deck[card].family }}
              </p>
              <p class="mb-0 text-center text-white font-weight-semibold">{{ $deck[card].name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyCards",
}
</script>
