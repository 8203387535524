<template>
  <div class="position-fixed z-first">
    <div class="position-fixed w-100 h-100 bg-dark overflow-scroll">
      <div class="bg-pattern position-fixed w-100 h-100 pointer-events-none"></div>
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-12">
            <div class="row">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-10 col-xl-8 mx-auto">
                    <div class="row">
                      <div class="col-12 my-4 my-md-5">
                        <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center" @click="$store.display.families = false">
                          <img src="/images/pictos/ic_close.svg" class="mr-2" alt="Icone ferme familles jeu Appui" width="18px" height="18px">
                          Fermer
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-4 text-white text-center">
                        <h5 class="mb-4 font-weight-semibold">Tableau des familles</h5>
                        <p class="big">Retrouvez toutes les familles ainsi que leurs membres</p>
                      </div>
                    </div>

                    <div class="row mb-5">
                      <div class="col-12">

                        <div v-for="(fam, index) in families" :key="index" class="tabletop-wrapper bg-info p-1 mb-3 text-white">
                          <div class="tabletop p-4 w-100">
                            <div class="d-flex align-items-center familyList-dropdown rounded btn btn-link btn-block text-white p-0" :class="{'opened' : display == fam.name}" @click="display = display == fam.name ? null : fam.name">
                              <p class="mb-0 font-weight-semibold h5">Famille {{ fam.name }}</p>
                              <img src="/images/pictos/ic_plus_white.svg" class="ml-auto plus" alt="Icone plus 7 familles Appui">
                            </div>

                            <transition name="growDownRel">
                              <div v-show="display == fam.name">
                                <div class="row">
                                  <div v-for="(card, index) in fam.cards" :key="index" class="mt-5 mb-3 col-12 col-md-6">
                                    <div class="row align-items-center">
                                      <div class="col-auto">
                                        <div class="family-card rounded border border-white bg-white text-center position-relative">
                                          <p class="h5 mb-0 text-white card-letter border-white d-flex align-items-center justify-content-center position-absolute" :style="'background-color:'+card.color">
                                            {{ card.family[0] }}
                                          </p>
                                          <img :src="card.image" :srcset="card.image2x+' 2x'" :alt="'Illustration '+card.name+' jeu 7 familles Appui'" class="img-fluid-all px-2 pt-3 pb-1">
                                        </div>
                                      </div>
                                      <div class="col"><h4>{{ card.name }}</h4></div>
                                    </div>
                                    <p class="mt-4">{{ card.description }}</p>
                                  </div>
                                </div>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 align-self-end bg-primary-light">
            <div class="row mx-0">
              <div class="col-12 align-self-end">
                <div class="row">
                  <div class="container py-4">
                    <div class="row">
                      <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto">
                        <p class="text-center">Si vous vous êtes retrouvé(e) dans l'une de ces familles, n'hésitez pas à contacter Info-aidant.</p>

                        <a href="tel:1 855 852-7784" @click="triggerCall" class="btn btn-sm btn-light btn-block d-flex align-items-center justify-content-center">
                          <img src="/images/pictos/ic_phone.svg" class="mr-2" alt="Téléphone Appui proches aidants" width="18px" height="18px">
                          1 855 852-7784
                        </a>
                        <button type="button" class="btn btn-sm btn-light btn-block mt-3 d-flex align-items-center justify-content-center"
                          @click="$store.display.modalAppui = true">
                          <img src="/images/pictos/ic_external_link.svg" class="mr-2" alt="Icone lien externe Appui.org" width="18px" height="18px">
                          lappui.org
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Families',
  data() {
    return {
      families: {},
      display: null,
    }
  },
  beforeMount() {

    // for(let index in this.$deck) {
    //   if(!this.cards.find(element => element == index)) {
    //     this.cardsToAsk[index] = this.$deck[index];
    //   }
    // }

    // on peuple cardsToAsk avec toutes les cartes que l'on ne possède pas
    for( let card in this.$deck ) {
      if (!this.families[this.$deck[card].family]) {
        this.families[this.$deck[card].family] = {};
        this.families[this.$deck[card].family].cards = {};
      }
      this.families[this.$deck[card].family].name = this.$deck[card].family;
      this.families[this.$deck[card].family].color = this.$deck[card].color;
      this.families[this.$deck[card].family].cards[card] = this.$deck[card]
    }
  },
  mounted() {
    this.$gtm.trackEvent({event:'tableau-famillesr'})
  },
  methods: {
    triggerCall() {
      this.$gtm.trackEvent({event:'telephone'})
    }
  }
}
</script>
