<template>
  <div class="my-4 text-center position-relative">
    <div class="empty-deck position-absolute rounded"></div>
    <transition-group :name="transition" tag="div" class="stack mx-auto">
      <div class="stack-wrapper position-absolute w-100 h-100" v-for="n in this.$store.gameLogic.deck" :key="n">
        <div class="stack-card border-white d-flex align-items-center justify-content-center"></div>
      </div>
    </transition-group>

    <p class="mb-0 deck-number text-white-50 font-weight-bold position-absolute" v-if="$store.gameLogic.deck > 0">{{ $store.gameLogic.deck }}</p>

    <transition name="flipSlideDown">
      <div class="card-picked bg-white border-white position-absolute" v-if="$store.gameLogic.statut == 'newCard'">
        <p class="mb-0 text-white card-letter border-white d-flex align-items-center justify-content-center position-absolute"
          :style="'background-color:'+$deck[$store.gameLogic.action.cardID].color">
          {{ $store.gameLogic.action.cardFamily[0] }}
        </p>
        <img :src="$deck[$store.gameLogic.action.cardID].image" :srcset="$deck[$store.gameLogic.action.cardID].image2x+' 2x'" alt="" class="img-fluid-all pt-2 py-2 pb-1">
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Stack",
  data() {
    return {
      transition : 'flipDown'
    }
  },
  beforeUpdate() {
    let turn = this.$store.gameLogic.actualTurn
    let myPlayerLayout = this.$store.selfPlayer.myPlayerLayout
    console.log('TEST TURN',turn,myPlayerLayout)
    if( turn === this.$store.selfPlayer.playerIndex ) {
      this.transition = 'flipDown'
    } else if( turn === myPlayerLayout[2] ) {
      this.transition = 'swipeUp'
      console.log('swipeUp')
    } else if( turn === myPlayerLayout[1] ||
               turn === myPlayerLayout[0] ) {
      this.transition = 'swipeLeft'
      console.log('swipeLeft')
    } else if( turn === myPlayerLayout[3] ||
               turn === myPlayerLayout[4] ) {
      this.transition = 'swipeRight'
      console.log('swipeRght')
    }
  }
}
</script>
