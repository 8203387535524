<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalCardDetails = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div>
            <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center mb-4 mb-md-5"
              @click="$store.display.modalCardDetails = false">
              <img src="/images/pictos/ic_left.svg" class="mr-2">
              Retour
            </button>
          </div>
          <transition name="fade">
            <div class="bg-white rounded-lg border border-white border-6 overflow-hidden">
              <div class="card-letter-lg text-center text-white d-flex align-items-center justify-content-center position-absolute border-0" :style="'background-color:'+$deck[$store.display.modalCardDetails].color">
                {{ $deck[$store.display.modalCardDetails].family[0] }}
              </div>
              <div class="pt-4 px-4 text-center">
                <p class="h5 text-primary font-weight-bold">
                  Famille {{ $deck[$store.display.modalCardDetails].family }}
                </p>
              </div>
              <div class="p-4 text-center">
                <img :src="$deck[$store.display.modalCardDetails].image" :srcset="$deck[$store.display.modalCardDetails].image2x+' 2x'" alt="" class="img-fluid-all" height="300px">
                <p class="h5 font-weight-bold mt-3">
                  {{ $deck[$store.display.modalCardDetails].name }}
                </p>
                <p class="mb-0 mt-3">
                  {{ $deck[$store.display.modalCardDetails].description }}
                </p>
              </div>
              <div class="p-4 position-relative">
                <div class="position-absolute bg-card-detail" :style="'background-color:'+$deck[$store.display.modalCardDetails].color"></div>
                <p class="font-weight-semibold text-center mb-1 position-relative">Les autres membres de la famille</p>
                <p class="text-center mb-4 small position-relative">Cliquez sur une carte pour avoir plus de détails</p>

                <div class="row justify-content-center">
                  <div v-for="(card, index) in cardsOfFamily" :key="index" class="col-6 col-sm-4" @click="$store.display.modalCardDetails = index">
                    <div class="mycard mycard-sm border-white bg-white mb-3 mx-auto text-center">
                      <p class="mb-0 text-white card-letter border-white d-flex align-items-center justify-content-center position-absolute top-0" :style="'background-color:'+card.color">
                        {{ card.family[0] }}
                      </p>
                      <img :src="card.image" :srcset="card.image2x+' 2x'" alt="" class="img-fluid-all pt-2 px-2 pb-1">
                    </div>
                    <p class="text-center small font-weight-semibold">{{ card.name }}</p>

                  </div>
                </div>
                <button type="button" class="position-relative btn btn-sm btn-primary btn-block mt-3" @click="$store.display.modalCardDetails = false">
                  Retour
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import easyScroll from 'easy-scroll';

export default {
  name: 'CardDetails',
  data() {
    return {
      cardsOfFamily : {},
      askedCard : {},
    }
  },
  methods: {
    updateModal() {
      this.askedCard = this.$deck[this.$store.display.modalCardDetails];
      this.cardsOfFamily = {};
      for(let i in this.$deck) {
        if(this.$deck[i].family == this.askedCard.family && i != this.$store.display.modalCardDetails) {
          this.cardsOfFamily[i] = this.$deck[i]
        }
      }
    },
  },
  created() {
    this.updateModal();
  },
  beforeUpdate() {
    easyScroll({
      'scrollableDomEle': document.querySelector('.modal-wrapper'),
      'direction': 'top',
      'duration': 500,
      'easingPreset': 'easeInOutCubic',
    });
  }
}
</script>
