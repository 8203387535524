<template>
  <div class="position-fixed w-100 h-100 overflow-scroll z-first">
    <div class="bg-dark-95 position-fixed w-100 h-100"></div>
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12 align-self-start">
          <div class="row">

            <div class="col-sm-10 col-md-8 col-lg-6 col-xl-6 mx-auto align-self-start my-4 my-md-5">
              <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center float-left" @click="closeTuto">
                <img src="/images/pictos/ic_close.svg" class="mr-2" alt="Icone fermer menu Appui 7 familles" width="18px" height="18px">
                Passer le tutoriel
              </button>

              <transition name="fade">
                <div v-if="step === 7">
                  <button v-if="step === 7" type="button" class="btn btn-light py-2 px-3 d-flex align-items-center ml-auto" @click="$store.display.menu = true">
                    <img src="/images/pictos/ic_menu.svg" class="mr-2">
                    Menu
                  </button>
                  <div class="bg-white p-3 p-md-4 mt-4 text-center rounded arrow-top-right">
                    <p class='mb-0' v-if="step == 7" key="7">
                      Grâce au menu, vous pouvez rapidement accéder aux <span class="text-primary">règles du jeu</span>, <span class="text-primary">quitter la partie</span> et avoir accès aux <span class="text-primary">contenus additionnels</span> proposés par le jeu.
                    </p>
                  </div>
                  <div class="text-center widescreen-tuto-nav">
                    <button type="button" class="btn btn-primary mx-auto mt-5 px-4" @click="closeTuto">C'est parti!</button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div :key="1" v-show="step === 0" class="col-sm-10 col-md-8 col-lg-6 col-xl-6 mx-auto align-self-middle my-4 my-md-5 text-center text-white">
          <h2 class="font-weight-bold mb-3">
            Bonjour {{ $store.selfPlayer.playername }} et bienvenue dans le jeu des 7 familles!
          </h2>
          <p>Voici votre table de jeu</p>
          <button type="button" class="btn btn-primary px-4" @click="step = 1">
            Voir le tutoriel
          </button>
        </div>

        <div class="col-12" :key="2" v-show="step !== 0">
          <div class="row h-100">
            <div class="col-auto align-self-center ml-auto widescreen-tuto-nav">
              <button type="button" class="btn btn-sm btn-light mb-5" @click="step--">Retour</button>
            </div>
            <div class="col-sm-10 col-md-8 col-lg-6 col-xl-6 mx-auto align-self-middle mb-4 mb-md-5 text-center">
              <div class="row h-100">
                <div class="col-12">
                  <div class="tuto-wrapper">

                    <!-- <transition-group name="fade"> -->
                      <div class="row align-items-center" v-show="step < 6" :key="1">
                        <!-- <div class="col-12">
                          <TutoPlayer position="left" avatar="5"/>
                        </div> -->
                        <div class="col-4">
                          <div class="row h-100">
                            <div class="col-12 align-items-center d-flex justify-content-center">
                              <TutoPlayer position="left" avatar="5" :play="step <= 4" :askTo="step" :ask="step == 1 || step == 3"/>
                              <transition name="tutoGiveCard">
                                <div class="card-tuto border-white position-absolute" v-show="step >= 2"></div>
                              </transition>
                            </div>
                            <div class="col-12 align-items-end d-flex justify-content-center">
                              <TutoPlayer position="left" avatar="3" :draw="step == 4"/>
                            </div>
                          </div>
                        </div>

                        <!-- DECK -->
                        <div class="col-4">
                          <transition name="fade">
                            <div class="my-4 text-center" v-show="step == 4">
                              <transition-group name="swipeLeftDelayed" tag="div" class="stack mx-auto">
                                <div class="stack-wrapper position-absolute w-100 h-100" v-for="n in (10 - step)" :key="n">
                                  <div class="stack-card border-white"></div>
                                </div>
                              </transition-group>
                            </div>
                          </transition>
                        </div>

                        <div class="col-4">
                          <div class="row h-100">
                            <div class="col-12 align-items-center d-flex justify-content-center">
                              <TutoPlayer avatar="4" position="right" :play="step == 5" :askTo="step" :ask="step == 5"/>
                            </div>
                            <div class="col-12 align-items-center d-flex justify-content-center">
                              <TutoPlayer avatar="1" position="right" :card="step == 2" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-show="step == 6" class="bg-white rounded" :key="2">
                        <div class="container-fluid py-4 text-center">
                          <div class="row">
                            <div class="col-12 text-center mb-4">
                              <img src="/images/gameover.png" srcset="/images/gameover@2x.png 2x" alt="">
                            </div>
                          </div>
                          <div class="row m-0">
                            <div class="col-auto pl-2">
                              <p class="font-weight-semibold small">Joueurs</p>
                            </div>
                            <div class="col-auto ml-auto pr-4">
                              <p class="font-weight-semibold small">Familles composées</p>
                            </div>
                          </div>

                          <div>
                            <div class="row m-0 py-2 rounded-pill align-items-center bg-yellow-light mb-2">
                              <div class="col-auto px-2">
                                <img src="/images/avatars/Avatar4.png"
                                  srcset="/images/avatars/Avatar4@2x.png 2x" alt=""
                                  class="rounded-circle img-fluid avatar" width="50px">
                              </div>
                              <div class="col px-2">
                                <p class="mb-0 font-weight-semibold text-yellow text-left text-yellow">
                                  Bravo, Andreanne a gagné!
                                </p>
                              </div>
                              <div class="col-auto ml-auto pr-4">
                                <p class="mb-0 rounded-sm border border-white bg-danger text-white px-2 py-1 shadow">
                                  4
                                </p>
                              </div>
                            </div>
                            <div class="row m-0 py-2 rounded-pill align-items-center mb-2">
                              <div class="col-auto px-2">
                                <img src="/images/avatars/Avatar5.png"
                                  srcset="/images/avatars/Avatar5@2x.png 2x" alt=""
                                  class="rounded-circle img-fluid avatar" width="50px">
                              </div>
                              <div class="col px-2">
                                <p class="mb-0 font-weight-semibold text-muted text-left text-yellow">
                                  Johannie
                                </p>
                              </div>
                              <div class="col-auto ml-auto pr-4">
                                <p class="mb-0 rounded-sm border border-white bg-danger text-white px-2 py-1 shadow">
                                  2
                                </p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    <!-- </transition-group> -->

                  </div>
                </div>

                <div class="col-12 align-self-end">
                  <transition name="fade">
                    <div class="message-wrapper mt-4">
                      <div class="bg-white p-3 p-md-4 text-center rounded arrow-top-center shadow-sm" v-if="step < 7">
                        <transition name="fade" mode="out-in">
                          <p class='mb-0' v-if="step == 1" key="1">
                            À chaque manche, le joueur dont c’est le tour demande à un autre joueur une carte appartenant à une famille qu’il souhaite compléter dans sa main.
                          </p>
                          <p class='mb-0' v-if="step == 2" key="2">
                            Si l’autre joueur possède la carte demandée, <span class="text-primary">il doit la céder.</span>
                          </p>
                          <p class='mb-0' v-if="step == 3" key="3">
                            <span class="text-primary">Aussi longtemps que le joueur reçoit les cartes demandées</span>, son tour continue.
                          </p>
                          <p class='mb-0' v-if="step == 4" key="4">
                            Si l’autre joueur ne possède pas la carte demandée, le joueur qui a demandé la carte <span class="text-primary">pige une carte dans la pioche.</span>
                          </p>
                          <p class='mb-0' v-if="step == 5" key="5">
                            <span class="text-primary">Son tour se termine</span> et c’est au tour du joueur suivant.
                          </p>
                          <p class='mb-0' v-if="step == 6" key="6">
                            <span class="text-primary">Le joueur ayant complété le plus de familles</span> remporte la partie!
                          </p>
                        </transition>
                      </div>
                    </div>
                  </transition>
                  <div class="tuto-nav-wrapper mt-sm-3 mt-lg-4 mt-xl-5">
                    <div class="row align-items-center">
                      <div class="col-auto text-left pr-0" style="width: 100px">
                        <button type="button" class="btn btn-sm btn-link p-0 mt-3 text-white" @click="step--">Retour</button>
                      </div>
                      <div class="col px-0">
                        <div class="tuto-nav">
                          <button v-for="i in 7" :key="i" @click="step = i" type="button" :class="{'tns-nav-active' : step == i}"></button>
                        </div>
                      </div>
                      <div class="col-auto text-right pl-0" style="width: 100px">
                        <button v-if="step < 7" type="button" class="btn btn-sm btn-link p-0 pl-2 mt-3 text-white" @click="step++">Suivant</button>
                        <button v-else type="button" class="btn btn-sm btn-link p-0 mt-3 text-white" @click="closeTuto">C'est parti!</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-auto align-self-center mr-auto widescreen-tuto-nav">
              <button v-if="step < 7" type="button" class="btn btn-sm btn-light mb-5" @click="step++">Suivant</button>
              <!-- <button v-else type="button" class="btn btn-sm btn-light" @click="closeTuto">C'est parti!</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TutoPlayer from './TutoPlayer.vue'

export default {
  name: 'Tutorial',
  components: {
    TutoPlayer
  },
  data() {
    return {
      step: 0,
      myPlayerLayout: ["0",0,0,1,2],
      players:[
        {
          "playername": "Brandon Cormier",
          "playerID": "bot-3",
          "socketID": "",
          "playerIndex": 3,
          "avatar": 3,
          "bot": false,
          "reserved": false,
          "cards": [],
          "score": 0
        },
        {
          "playername": "Jonathan Comeau",
          "playerID": "bot-4",
          "socketID": "",
          "playerIndex": 4,
          "avatar": 12,
          "bot": false,
          "reserved": false,
          "cards": [],
          "score": 0
        },
        {
          "playername": "Maxim Le",
          "playerID": "bot-5",
          "socketID": "",
          "playerIndex": 5,
          "avatar": 3,
          "bot": false,
          "reserved": false,
          "cards": [],
          "score": 0
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    closeTuto() {
      this.$store.display.tutorial = false;
      sessionStorage.setItem('tutorialClosed', true)
    }
  }
}
</script>
