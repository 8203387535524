<template>
  <div>
    <transition name="growUp">
      <div class="bulle bg-white border rounded-circle position-absolute" :class="position"
      v-show="$store.gameLogic.action.toPlayerID == player.playerID &&
            $store.gameLogic.statut == 'playerNewCard'">
        <img src="/images/pictos/ic_check.svg" alt="Icone carte trouvée Appui 7 familles" width="40px" height="40px">
      </div>
    </transition>

    <transition name="growUp">
      <div class="bulle bg-white border rounded-circle position-absolute" :class="position"
            v-show="$store.gameLogic.action.toPlayerID == player.playerID &&
              ($store.gameLogic.statut == 'playerDraw' ||
              $store.gameLogic.statut == 'emptyDraw')"
            >
        <img src="/images/pictos/ic_cancel.svg" alt="Carte non trouvée Appui 7 familles" width="40px" height="40px">
      </div>
    </transition>

    <transition name="growUp">
      <div v-show="$store.gameLogic.statut == 'playerAskTo' && $store.gameLogic.action.fromPlayerID === player.playerID"
            class="bulle-square position-absolute" :class="position">
        <div class="box-square-content bg-white border rounded p-3 d-flex align-items-center justify-content-between" :class="'float-'+position">
          <img v-if="$store.gameLogic.action.toPlayerID !== ''"
            class="rounded-circle img-fluid avatar"
            :src="'/images/avatars/Avatar'+ $store.players[$store.gameLogic.action.toPlayerID].avatar +'.png'"
            :srcset="'/images/avatars/Avatar'+ $store.players[$store.gameLogic.action.toPlayerID].avatar +'@2x.png 2x'">
          <!-- {{ $store.gameLogic.action.toPlayerID }} -->
          <p class="ml-2 mb-0 p-1 text-white h2 rounded" :style="'width:46px;background-color:'+$deck[$store.gameLogic.action.cardID].color">{{ $store.gameLogic.action.cardFamily[0] }}</p>
          <p class="ml-2 mb-0 font-weight-semibold">{{ $store.gameLogic.action.cardName }}?</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Bulles",
  props: ['player','position'],
  data() {
    return {
      avatar : this.player ? this.player.avatar : "",
      toPlayerAvatar : this.$store.gameLogic.action.toPlayerID ? this.$store.players[this.$store.gameLogic.action.toPlayerID].avatar : '',
    }
  },
}
</script>
