<template>
  <div>
    <div class="d-flex align-items-center justify-content-around message-wrapper">
      <div class="rounded bg-white text-center p-3 w-100">
        <p class="mb-0 font-weight-semibold message">
          <transition name="fade" mode="out-in">

            <span :key="$store.gameLogic.statut" v-if="$store.gameLogic.statut == 'starting'">
              Distribution des cartes...
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'playerTurn' || $store.gameLogic.statut == 'askCard'">
              <span v-if="$store.selfPlayer.playerIndex !== $store.gameLogic.actualTurn">
                C'est au tour de {{ $store.players[$store.gameLogic.playerOrder[$store.gameLogic.actualTurn]].playername }}.
              </span>
              <span v-else>
                C'est à vous! <br><strong>Sélectionnez le joueur de votre choix</strong> pour lui demander une carte.
              </span>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'playerAskTo'">
              <span v-if="$store.selfPlayer.playerIndex === $store.gameLogic.actualTurn">
                Vous demandez à {{ $store.players[$store.gameLogic.action.toPlayerID].playername }} <strong> {{ $store.gameLogic.action.cardName }} </strong> de la famille <strong>{{ $store.gameLogic.action.cardFamily }}</strong>...
              </span>
              <span v-else-if="$store.gameLogic.action.toPlayerID !== $store.selfPlayer.playerID">
                {{ $store.players[$store.gameLogic.action.fromPlayerID].playername }} demande à {{ $store.players[$store.gameLogic.action.toPlayerID].playername }} <strong> {{ $store.gameLogic.action.cardName }} </strong> de la famille <strong> {{ $store.gameLogic.action.cardFamily }}</strong>...
              </span>
              <span v-else>
                {{ $store.players[$store.gameLogic.action.fromPlayerID].playername }} vous demande <strong> {{ $store.gameLogic.action.cardName }} </strong> de la famille <strong>{{$store.gameLogic.action.cardFamily}}</strong>...
              </span>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'emptyHand'">
              Plus de cartes en mains...
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'playerPickCard'">
              <span v-if="$store.gameLogic.action.toPlayerID !== $store.selfPlayer.playerID">
                {{ $store.players[$store.gameLogic.action.fromPlayerID].playername }} pioche une carte.
              </span>
              <span v-else>
                Vous piochez une carte.
              </span>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'newCard'">
              Vous piochez <strong>{{ $store.gameLogic.action.cardName }}</strong> de la famille <strong>{{ $store.gameLogic.action.cardFamily }}</strong>.
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'playerNewCard'">
              <span v-if="$store.selfPlayer.playerIndex === $store.gameLogic.actualTurn">
                Vous prenez la carte demandée à {{ $store.players[$store.gameLogic.action.toPlayerID].playername }}.
              </span>
              <span v-else>
                {{ $store.players[$store.gameLogic.action.fromPlayerID].playername }} prend la carte demandée à {{ $store.players[$store.gameLogic.action.toPlayerID].playername }}.
              </span>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'playerReplay'">
              <span v-if="$store.selfPlayer.playerIndex !== $store.gameLogic.actualTurn">
                C’est encore à {{ $store.players[$store.gameLogic.playerOrder[$store.gameLogic.actualTurn]].playername }}.
              </span>
              <span v-else>
                C’est encore à vous : <span>demandez une carte au joueur de votre choix.</span>.
              </span>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'playerDraw'">
              <span v-if="$store.gameLogic.action.toPlayerID !== $store.selfPlayer.playerID">
                {{ $store.players[$store.gameLogic.action.toPlayerID].playername }} ne possède pas la carte demandée. <strong>{{ $store.players[$store.gameLogic.action.fromPlayerID].playername }} pige dans la pioche.</strong>
              </span>
              <span v-else>
                Vous ne possédez pas la carte demandée. <strong>{{ $store.players[$store.gameLogic.action.fromPlayerID].playername }} pige dans la pioche.</strong>
              </span>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'directDraw'">
              <strong>Vous pigez dans la pioche.</strong>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'emptyDraw'">
              {{ $store.players[$store.gameLogic.action.toPlayerID].playername }} ne possède pas la carte demandée. <strong>Mais la pioche est vide.</strong>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'playerFamily'">
              <span v-if="$store.selfPlayer.playerIndex !== $store.gameLogic.actualTurn">
                <strong>Famille!<br>La carte {{ $store.gameLogic.action.cardName }} complète sa main!</strong><br>C’est encore à son tour.
              </span>
              <span v-else>
                <strong>Famille!<br>La carte {{ $store.gameLogic.action.cardName }} complète votre main!</strong>
              </span>
            </span>

            <span :key="$store.gameLogic.statut" v-else-if="$store.gameLogic.statut == 'goodPick'">
              <strong>Bonne pioche!</strong>
            </span>

          </transition>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
}
</script>
