<template>
  <div class="position-fixed w-100 h-100 bg-dark overflow-scroll z-first">
    <div class="bg-pattern position-fixed w-100 h-100 pointer-events-none"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 mx-auto align-self-start my-4 my-md-5">
              <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center" @click="$store.display.rules = false">
                <img src="/images/pictos/ic_close.svg" class="mr-2" alt="Icone fermer menu Appui 7 familles" width="18px" height="18px">
                Fermer
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-8 mx-auto text-white mb-5">
          <h5 class="mb-4 font-weight-semibold">Règles du jeu</h5>

          <p>Les 7 familles se joue de 2 à 6 joueurs.</p>

          <p>Le but du jeu des 7 familles est d’être le joueur ayant, à la fin de la partie, réussi à réunir le plus de familles complètes.</p>

          <p>Le jeu est composé de 42 cartes pour 7 familles.</p>

          <p>1 - Chaque joueur reçoit 6 cartes. Le reste des cartes est placé au centre et fait office de pioche.</p>
          <p>2 - Le joueur dont c'est le tour demande une carte à la personne de son choix. Attention, il ne peut demander une carte d’une famille seulement s’il en possède déjà une dans son jeu.</p>
          <p>3 - Si l'autre joueur possède la carte demandée, il la cède. Aussi longtemps que le joueur reçoit les cartes demandées, son tour continue et il peut demander une nouvelle carte à un joueur.</p>
          <p>4 - Si l'autre joueur ne possède pas la carte demandée, la personne qui jouait pige une carte dans la pioche. Si elle pige la carte qu’elle souhaitait, elle peut rejouer. Sinon, son tour se termine et c’est le tour du joueur suivant.</p>
          <p>5 - Dès qu’un joueur réunit une famille, il la pose devant lui et il poursuit son tour. S’il n’a plus de carte en main après avoir formé la famille, il peut piocher puis poursuivre son tour. S’il n’y a plus de carte à piocher, son tour se termine.</p>
          <p>6 - La partie s’arrête quand toutes les familles sont complétées.</p>

          <p>Le joueur ayant complété le plus de familles remporte la partie!</p>

        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="bg-white rounded-lg p-4 p-md-5 mb-5">

            <p class="font-weight-semibold h5 mb-5">Liste des familles</p>

            <div v-for="(fam, index) in families" :key="index">
              <p class="font-weight-semibold big">Famille {{ fam.name }}</p>

              <div class="row flex-nowrap pt-3 overflow-x-scroll">
                <div v-for="(card, index) in fam.cards" :key="index" class="col text-center">
                  <div class="mycard rounded border border-white mb-3" @click="$store.display.modalCardDetails = index">
                    <p class="mb-0 text-white card-letter border-white d-flex align-items-center justify-content-center position-absolute"
                      :style="'background-color:'+card.color">
                      {{ card.family[0] }}
                    </p>
                    <img :src="card.image" :srcset="card.image2x+' 2x'" :alt="'Illustration '+card.name+' jeu 7 familles Appui'" class="img-fluid-all pt-2 px-2 pb-1">
                  </div>
                  <p>{{ card.name }}</p>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Rules',
  data() {
    return {
      families: {}
    }
  },
  beforeMount() {
    for( let card in this.$deck ) {
      if (!this.families[this.$deck[card].family]) {
        this.families[this.$deck[card].family] = {};
        this.families[this.$deck[card].family].cards = {};
      }
      this.families[this.$deck[card].family].name = this.$deck[card].family;
      this.families[this.$deck[card].family].color = this.$deck[card].color;
      this.families[this.$deck[card].family].cards[card] = this.$deck[card]
    }
  },
  mounted() {
    this.$gtm.trackEvent({event:'regles'})
    console.log('GTM Rules')
  }
}
</script>
