const deckStore = [{
    family: "Côté",
    color: "#00AEEF",
    name: "Le grand-père",
    description: "Paul, 86 ans. Il vit seul à son domicile et a arrêté de conduire il y a quelques mois, mais sa fille l’aide beaucoup. Il aime leurs moments ensemble. ",
    image: "/images/Personnages_detourees/01_Cote/01_Grand_pere.png",
    image2x: "/images/Personnages_detourees/01_Cote/01_Grand_pere@2x.png",
  }, {
    family: "Côté",
    color: "#00AEEF",
    name: "La mère",
    description: "Olivia s’occupe de l’épicerie et des rendez-vous médicaux de son papa, le voit chaque semaine et l’appelle tous les jours. Entre lui, sa fille et son travail, elle est débordée! C’est une proche aidante.",
    image: "/images/Personnages_detourees/01_Cote/02_Mere.png",
    image2x: "/images/Personnages_detourees/01_Cote/02_Mere@2x.png",
  }, {
    family: "Côté",
    color: "#00AEEF",
    name: "Le père",
    description: "Pierre élève sa fille aux côtés de sa femme, mais est souvent en déplacement. Il aide parfois son beau-père, notamment dans le jardin. C’est un proche aidant. ",
    image: "/images/Personnages_detourees/01_Cote/03_Pere.png",
    image2x: "/images/Personnages_detourees/01_Cote/03_Pere@2x.png",
  }, {
    family: "Côté",
    color: "#00AEEF",
    name: "La fille",
    description: "Marion, 16 ans. C’est une ado qui aime la musique et les sorties avec ses amies. Elle est bonne élève et a beaucoup d’activités extrascolaires. ",
    image: "/images/Personnages_detourees/01_Cote/04_Fille.png",
    image2x: "/images/Personnages_detourees/01_Cote/04_Fille@2x.png",
  }, {
    family: "Côté",
    color: "#00AEEF",
    name: "L’employeuse de la mère",
    description: "Myriam est l’employeuse d’Olivia. Elle fait preuve de compréhension quand Olivia est confrontée à des urgences personnelles. ",
    image: "/images/Personnages_detourees/01_Cote/05_Employeuse_de_la_mere.png",
    image2x: "/images/Personnages_detourees/01_Cote/05_Employeuse_de_la_mere@2x.png",
  }, {
    family: "Côté",
    color: "#00AEEF",
    name: "La conseillère - Info-aidant",
    description: "Sarah a été la conseillère d’Olivia chez Info-aidant. Elle l’a écoutée parler de sa situation et vient de lui suggérer des services de répit afin de lui permettre de prendre une pause. ",
    image: "/images/Personnages_detourees/01_Cote/06_Conseillere_IA.png",
    image2x: "/images/Personnages_detourees/01_Cote/06_Conseillere_IA@2x.png",
  }, {
    family: "Garcia",
    color: "#FFCB05",
    name: "Le grand-père",
    description: "Ricardo, 82 ans. Il rend visite une fois par semaine à sa conjointe qui vit en CHSLD. Même s’il se sent très seul, devant Rachel, il fait bonne figure! C’est un proche aidant.",
    image: "/images/Personnages_detourees/02_Garcia/01_Grand_pere.png",
    image2x: "/images/Personnages_detourees/02_Garcia/01_Grand_pere@2x.png",
  }, {
    family: "Garcia",
    color: "#FFCB05",
    name: "La grand-mère",
    description: "Rachel, 81 ans. Atteinte de la maladie d’Alzheimer, elle vit en CHSLD. Son état se dégrade petit à petit mais elle aime partager un carré de chocolat avec ses proches lorsqu’ils lui rendent visite.",
    image: "/images/Personnages_detourees/02_Garcia/02_Grand_mere.png",
    image2x: "/images/Personnages_detourees/02_Garcia/02_Grand_mere@2x.png",
  }, {
    family: "Garcia",
    color: "#FFCB05",
    name: "La mère",
    description: "Marie, 42 ans. Elle est la fille de Ricardo et Rachel, mais ne voit pas beaucoup ses parents, car elle a déménagé dans le Centre-du-Québec. ",
    image: "/images/Personnages_detourees/02_Garcia/03_Mere.png",
    image2x: "/images/Personnages_detourees/02_Garcia/03_Mere@2x.png",
  }, {
    family: "Garcia",
    color: "#FFCB05",
    name: "La fille",
    description: "Joséphine, 13 ans. Elle rend visite à son grand-père à chaque vacances scolaires et adore les gâteaux au chocolat qu’ils font ensemble. ",
    image: "/images/Personnages_detourees/02_Garcia/04_Fille.png",
    image2x: "/images/Personnages_detourees/02_Garcia/04_Fille@2x.png",
  }, {
    family: "Garcia",
    color: "#FFCB05",
    name: "La préposée aux bénéficiaires",
    description: "Elle travaille dans l’étage où est installée Rachel. Au fil du temps, elle a développé beaucoup d’affection pour Ricardo, qui lui fait part de ses soucis lorsqu’elle a un peu de temps libre. ",
    image: "/images/Personnages_detourees/02_Garcia/05_Preposee_aux_beneficiaires.png",
    image2x: "/images/Personnages_detourees/02_Garcia/05_Preposee_aux_beneficiaires@2x.png",
  }, {
    family: "Garcia",
    color: "#FFCB05",
    name: "La conseillère - Info-aidant",
    description: "Cathy est la conseillère qui a répondu à Ricardo chez Info-aidant. Ricardo a beaucoup de difficultés à surmonter ce deuil blanc et a besoin de se confier.",
    image: "/images/Personnages_detourees/02_Garcia/06_Conseillere_IA.png",
    image2x: "/images/Personnages_detourees/02_Garcia/06_Conseillere_IA@2x.png",
  }, {
    family: "Jones",
    color: "#F15B32",
    name: "Le père",
    description: "Karl, 59 ans, est très fier des progrès que son fils Manu accomplit tous les jours, mais s’inquiète beaucoup pour l’avenir. C’est un proche aidant.",
    image: "/images/Personnages_detourees/03_Jones/01_Pere.png",
    image2x: "/images/Personnages_detourees/03_Jones/01_Pere@2x.png",
  }, {
    family: "Jones",
    color: "#F15B32",
    name: "La  mère",
    description: "Maddie, 58 ans, ne prévoit pas de prendre sa retraite rapidement: la famille a peur de ne plus avoir les moyens d’offrir une bonne prise en charge à leur fils. C’est une proche aidante.",
    image: "/images/Personnages_detourees/03_Jones/02_Mere.png",
    image2x: "/images/Personnages_detourees/03_Jones/02_Mere@2x.png",
  }, {
    family: "Jones",
    color: "#F15B32",
    name: "Le fils",
    description: "Manu, 26 ans. Il est atteint d’autisme. Bien conscient de sa différence, il est relativement indépendant, mais vit chez ses parents et adore leur petite vie. ",
    image: "/images/Personnages_detourees/03_Jones/03_Fils.png",
    image2x: "/images/Personnages_detourees/03_Jones/03_Fils@2x.png",
  }, {
    family: "Jones",
    color: "#F15B32",
    name: "Le chien",
    description: "Joe est un chien d’assistance pour Manu, mais c’est surtout son meilleur ami! Plus jeune, il l’a beaucoup aidé à réduire son stress. ",
    image: "/images/Personnages_detourees/03_Jones/04_Chien.png",
    image2x: "/images/Personnages_detourees/03_Jones/04_Chien@2x.png",
  }, {
    family: "Jones",
    color: "#F15B32",
    name: "La docteure",
    description: " Laura, 53 ans. Elle a tissé une vraie relation avec Manu, qu’elle suit depuis longtemps. Elle travaille dans un centre spécialisé vers lequel les Jones se sont tournés après un échange avec Info-aidant.",
    image: "/images/Personnages_detourees/03_Jones/05_Docteure.png",
    image2x: "/images/Personnages_detourees/03_Jones/05_Docteure@2x.png",
  }, {
    family: "Jones",
    color: "#F15B32",
    name: "La conseillère - Info-aidant",
    description: "Carmen a aidé les Jones à trouver les meilleures ressources disponibles pour Manu et leur a notamment donné des références pour profiter de journées de répit grâce à un centre de jour. ",
    image: "/images/Personnages_detourees/03_Jones/06_Conseillere_IA.png",
    image2x: "/images/Personnages_detourees/03_Jones/06_Conseillere_IA@2x.png",
  }, {
    family: "Nguyen",
    color: "#DE2F92",
    name: "La mère",
    description: "Claire Nguyen, 83 ans est touchée par une grosse arthrose, mais reste à domicile. Elle entretient une belle relation d’amitié avec sa voisine, Gabrielle, malgré leur différence d’âge.",
    image: "/images/Personnages_detourees/04_Nguyen/01_Mere.png",
    image2x: "/images/Personnages_detourees/04_Nguyen/01_Mere@2x.png",
  }, {
    family: "Nguyen",
    color: "#DE2F92",
    name: "Le fils",
    description: "Thomas a déménagé loin de Québec et entretient peu de liens avec sa mère. Il compte beaucoup sur l’aide de Gabrielle, la voisine de Claire. ",
    image: "/images/Personnages_detourees/04_Nguyen/02_Fils.png",
    image2x: "/images/Personnages_detourees/04_Nguyen/02_Fils@2x.png",
  }, {
    family: "Nguyen",
    color: "#DE2F92",
    name: "La voisine",
    description: "Gabrielle, 29 ans, rend souvent service à sa voisine. Son dicton : «\xa0entre voisines, c’est bien normal de s’entraider\xa0». C’est une proche aidante.",
    image: "/images/Personnages_detourees/04_Nguyen/03_Voisine.png",
    image2x: "/images/Personnages_detourees/04_Nguyen/03_Voisine@2x.png",
  }, {
    family: "Nguyen",
    color: "#DE2F92",
    name: "Le voisin",
    description: "Charles a moins de liens avec Claire, mais s’occupe de menus travaux de réparations pour elle. Il souhaite avoir des enfants avec Gabrielle dans les années à venir! ",
    image: "/images/Personnages_detourees/04_Nguyen/04_Voisin.png",
    image2x: "/images/Personnages_detourees/04_Nguyen/04_Voisin@2x.png",
  }, {
    family: "Nguyen",
    color: "#DE2F92",
    name: "L’aide soignante",
    description: "Léa, 32 ans, Elle s’occupe désormais des soins de Claire en venant une fois par semaine. C’est Gabrielle qui a recommandé à Claire de faire appel à ce type de service après un contact avec Info-aidant. ",
    image: "/images/Personnages_detourees/04_Nguyen/05_Aide_soignante.png",
    image2x: "/images/Personnages_detourees/04_Nguyen/05_Aide_soignante@2x.png",
  }, {
    family: "Nguyen",
    color: "#DE2F92",
    name: "La conseillère - Info-aidant",
    description: "Gabrielle l’a appelée récemment pour obtenir des références en matière de transport et d’accompagnement médical. ",
    image: "/images/Personnages_detourees/04_Nguyen/06_Conseillere_IA.png",
    image2x: "/images/Personnages_detourees/04_Nguyen/06_Conseillere_IA@2x.png",
  }, {
    family: "Roy",
    color: "#124F8E",
    name: "La conjointe M.",
    description: "Mayra a été diagnostiquée d’un cancer du sein il y a quelques mois. En pleine chimiothérapie, elle est épuisée. ",
    image: "/images/Personnages_detourees/05_Roy/01_Conjointe.png",
    image2x: "/images/Personnages_detourees/05_Roy/01_Conjointe@2x.png",
  }, {
    family: "Roy",
    color: "#124F8E",
    name: "La conjointe T.",
    description: "Tess est en couple avec Mayra. En ce moment, elle gère le quotidien et toute la logistique pour les soins, tout en étant cheffe d’entreprise. La situation est très difficile pour elle. C’est une proche aidante. ",
    image: "/images/Personnages_detourees/05_Roy/02_Conjointe.png",
    image2x: "/images/Personnages_detourees/05_Roy/02_Conjointe@2x.png",
  }, {
    family: "Roy",
    color: "#124F8E",
    name: "L’associée",
    description: "Émilie est l’associée de Tess et Moussa. Elle prend en charge certaines des missions de Tess, car celle-ci a très peu de temps à consacrer au travail en ce moment.  ",
    image: "/images/Personnages_detourees/05_Roy/03_Associee.png",
    image2x: "/images/Personnages_detourees/05_Roy/03_Associee@2x.png",
  }, {
    family: "Roy",
    color: "#124F8E",
    name: "L’associé",
    description: "Moussa est l’associé de Tess et Émilie. Il est très compréhensif, mais il s’inquiète beaucoup de l’avenir de leur entreprise. Cela génère des discussions compliquées. ",
    image: "/images/Personnages_detourees/05_Roy/04_Associe.png",
    image2x: "/images/Personnages_detourees/05_Roy/04_Associe@2x.png",
  }, {
    family: "Roy",
    color: "#124F8E",
    name: "La belle-mère",
    description: "À 82 ans, Prisha, la maman de Mayra, n’est plus en mesure d’accompagner sa fille au quotidien. Elle reste un soutien moral précieux pour le couple. C’est elle qui a recommandé à Tess de contacter Info-aidant.  ",
    image: "/images/Personnages_detourees/05_Roy/05_Belle_mere.png",
    image2x: "/images/Personnages_detourees/05_Roy/05_Belle_mere@2x.png",
  }, {
    family: "Roy",
    color: "#124F8E",
    name: "Le conseiller - Info-aidant",
    description: "Sur les conseils de Prisha, Tess a demandé à Info-aidant plusieurs informations concernant les solutions fiscales dont elle et Mayra peuvent bénéficier.",
    image: "/images/Personnages_detourees/05_Roy/06_Conseiller_IA.png",
    image2x: "/images/Personnages_detourees/05_Roy/06_Conseiller_IA@2x.png",
  }, {
    family: "Vollant",
    color: "#8DC73F",
    name: "Le grand-père",
    description: "Shapatesh a 77 ans. Ses activités favorites sont le jardinage et la pêche. Suite à une mauvaise chute, il s’est cassé un bras, ce qui le restreint beaucoup.",
    image: "/images/Personnages_detourees/06_Vollant/01_Grand_pere.png",
    image2x: "/images/Personnages_detourees/06_Vollant/01_Grand_pere@2x.png",
  }, {
    family: "Vollant",
    color: "#8DC73F",
    name: "La grand-mère",
    description: "Marjolaine, 76 ans, aime être entourée par sa famille et leur raconter de belles histoires au coin du feu.",
    image: "/images/Personnages_detourees/06_Vollant/02_Grand_mere.png",
    image2x: "/images/Personnages_detourees/06_Vollant/02_Grand_mere@2x.png",
  }, {
    family: "Vollant",
    color: "#8DC73F",
    name: "Le père",
    description: "Pien, 47 ans. Il est féru de technologies et, comme ses parents, il adore les réunions familiales. La chute de son papa l’a inquiété au sujet de l’avenir. C’est un proche aidant.",
    image: "/images/Personnages_detourees/06_Vollant/03_Pere.png",
    image2x: "/images/Personnages_detourees/06_Vollant/03_Pere@2x.png",
  }, {
    family: "Vollant",
    color: "#8DC73F",
    name: "La mère",
    description: "Abey Vollant, 44 ans, est aide-soignante. Ses compétences en santé lui permettent de s’occuper de son beau-père, qui s’est cassé le bras. C’est une proche aidante. ",
    image: "/images/Personnages_detourees/06_Vollant/04_Mere.png",
    image2x: "/images/Personnages_detourees/06_Vollant/04_Mere@2x.png",
  }, {
    family: "Vollant",
    color: "#8DC73F",
    name: "La fille",
    description: "Mali, 23 ans, est la fille de Pien et Abey. Elle a quitté le domicile familial pour s’installer à Ottawa, mais elle revient avec plaisir dès qu’elle a du temps libre.  ",
    image: "/images/Personnages_detourees/06_Vollant/05_Fille.png",
    image2x: "/images/Personnages_detourees/06_Vollant/05_Fille@2x.png",
  }, {
    family: "Vollant",
    color: "#8DC73F",
    name: "La conseillère - Info-aidant",
    description: "Charlène a renseigné Pien sur les solutions qui pourront être mises en place si ses parents perdent en autonomie.",
    image: "/images/Personnages_detourees/06_Vollant/06_Conseillere_IA.png",
    image2x: "/images/Personnages_detourees/06_Vollant/06_Conseillere_IA@2x.png",
  }, {
    family: "Alami",
    color: "#4CA2AE",
    name: "Le père",
    description: "Amir, 72 ans. Atteint de schizophrénie depuis plusieurs années, il est divorcé. Hormis sa fille, il n’a pas de famille proche. ",
    image: "/images/Personnages_detourees/07_Alami/01_Pere.png",
    image2x: "/images/Personnages_detourees/07_Alami/01_Pere@2x.png",
  }, {
    family: "Alami",
    color: "#4CA2AE",
    name: "La fille",
    description: "Nadia Alami, 37 ans. Fille unique, elle s’occupe de son père schizophrène. C’est une proche aidante. ",
    image: "/images/Personnages_detourees/07_Alami/02_Fille.png",
    image2x: "/images/Personnages_detourees/07_Alami/02_Fille@2x.png",
  }, {
    family: "Alami",
    color: "#4CA2AE",
    name: "La confidente - Groupe de soutien",
    description: "C’est une amie que Nadia a rencontré dans un groupe de soutien, car elle ne souhaite pas se confier sur ses problèmes familiaux à ses proches. Info-aidant lui a recommandé l’organisme. ",
    image: "/images/Personnages_detourees/07_Alami/03_Confidente_groupe_de_soutien.png",
    image2x: "/images/Personnages_detourees/07_Alami/03_Confidente_groupe_de_soutien@2x.png",
  }, {
    family: "Alami",
    color: "#4CA2AE",
    name: "L’infirmier - Soins à domicile",
    description: "Grâce aux informations données par Info-aidant, il rend visite tous les jours à Amir afin de vérifier son état mental. En cas de crise, il peut avertir Nadia très rapidement et prendre toutes les mesures nécessaires. ",
    image: "/images/Personnages_detourees/07_Alami/04_Infirmier_soins_a_domicile.png",
    image2x: "/images/Personnages_detourees/07_Alami/04_Infirmier_soins_a_domicile@2x.png",
  }, {
    family: "Alami",
    color: "#4CA2AE",
    name: "La bénévole -  Aide aux repas",
    description: "Elle fait la conversation à Amir lorsqu’elle lui livre ses repas quotidiens. En effet, Nadia a souscrit à un service d’aide aux repas. ",
    image: "/images/Personnages_detourees/07_Alami/05_Benevole_aide_aux_repas.png",
    image2x: "/images/Personnages_detourees/07_Alami/05_Benevole_aide_aux_repas@2x.png",
  }, {
    family: "Alami",
    color: "#4CA2AE",
    name: "La conseillère - Info-aidant",
    description: "Nadia appelle le service dès qu’elle a besoin de parler ou de trouver une ressource. Grâce à Info-aidant, elle a pu s’entourer d’organismes pertinents pour aider son père. ",
    image: "/images/Personnages_detourees/07_Alami/06_Conseillere_IA.png",
    image2x: "/images/Personnages_detourees/07_Alami/06_Conseillere_IA@2x.png",
  },

]

export default deckStore;
