<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalLeave = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">

            <img src="/images/pictos/ic_door_black.svg" alt="Icone quitter la partie Appui 7 familles" class="mb-3" width="60px" height="48px">
            <p class="font-weight-semibold mb-1 big">
              Êtes-vous certain(e) de vouloir quitter la partie?
            </p>
            <p class="text-muted mb-4">
              Vous serez remplacé(e) par l’ordinateur.
            </p>
            <a href="/" class="btn btn-sm btn-primary btn-block mt-3">
              Quitter la partie
            </a>
          </div>
            <button type="button" class="btn btn-sm btn-light btn-block mt-3" @click="$store.display.modalLeave = false">
              Reprendre la partie
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalLeave',
}
</script>
