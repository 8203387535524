

const dataStore = {
    socket: '',  // <--- assigne ton socket a cette référence pour pouvoir l'utiliser partout
    selfPlayer: {   // <---- soit meme, nous avons besoin de connaitres les cartes en mains
        playername: '',
        playerID: '',
        bot: false,
        cards: [],  // <--- id des cartes en mains pour les afficher
        score: 0,
        profilePicture: 0,
    },
    players: [{
        playername: '',
        playerID: '',
        bot: false,
        nCards: 0, // <--- afin d'afficher le nombre de cartes en mains des adversaire (face caché)
        score: 0,
        profilePicture: 0
    }],
    display: {
        modalShare: false,
        modalWaitOthers: false,
        modalPlayerLeave: false,
        modalGameDeleted: false,
        modalCardDetails: false,
        modalYourTurn: false,
        modalLeave: false,
        modalGameOver: false,
        modalGameRestarted: false,
        modalAppui: false,
        rules: false,
        about: false,
        families: false,
        menu: false,
        mycards: false,
        tutorial: true,
    },
    gameLogic: {   // <--- ici on peut mettre toute les data lié au jeu
        deck: 0,  // <--- le nombre de cartes piochable
        actualTurn: 1,
        score: 0,
        playerLimit : 4,
        playerLayout : [],
        playerOrder: [],
        statut: 'starting',
        askToPlayer: {},
        onlyBot : false,
        action: {
            fromPlayerID: '',
            toPlayerID: '',
            cardID: '0',
            cardName: '',
            cardFamily: '',
        }
        // a complété...
    }
}

export default dataStore;
