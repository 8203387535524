<template>
  <div class="modal-mask z-first bg-dark-95">
    <div class="modal-wrapper" @click.self="$store.display.modalAppui = false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent p-0 border-0">
          <div class="bg-white rounded p-4 p-md-5 text-center">

            <img src="/images/pictos/ic_external_link.svg" alt="" class="mb-3" width="48px" height="48px">
            <p class="font-weight-semibold mb-1 big">
              Redirection vers l'appui.org
            </p>
            <p class="text-muted mb-4">
              Vous allez être redirigé(e) vers le site de l’Appui.<br>Voulez-vous continuer?
            </p>
            <a href="https://www.lappui.org/" @click="goToAppui" class="btn btn-sm btn-primary btn-block mt-3">
              Continuer
            </a>
          </div>
            <button type="button" class="btn btn-sm btn-light btn-block mt-3" @click="$store.display.modalAppui = false">
              Annuler
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAppui',
  methods: {
    goToAppui() {
      this.$gtm.trackEvent({event:'site'})
    }
  }
}
</script>
