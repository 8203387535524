<template>
  <div class="splashscreen w-100 h-100 position-fixed bg-secondary">
    <div class="container h-100">
      <div class="row h-100 py-3 py-lg-5">
        <div class="col-12 text-center align-self-center h-100 d-flex justify-content-center">
          <img src="/images/Loading_screen.png" srcset="/images/Loading_screen@2x.png 2x" class="img-fluid-all align-self-center" alt="Image accueil Appui jeu de 7 familles">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SplashScreen',
  }
</script>
