<template>
  <div>
    <div class="bg-pattern position-fixed h-100 w-100 pointer-events-none"></div>
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-12 align-self-start">
          <div class="row">
            <div class="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-5 mx-auto">
              <Header notStarted="true"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-5 mx-auto">
          <div class="py-3">
            <form @submit.prevent="create ? createRoom() : joinRoom()" autocomplete="off">
              <h2 class="text-center text-white font-weight-bold mb-3">
                Bonjour, <span class="font-weight-light">{{ me.playername }}</span>
              </h2>
              <!-- <transition name="fade" mode="in-out"> -->
              <div v-show="formStep == 1">
                <div class="form-group mb-5">
                  <input type="text" class="form-control text-center" name="playername" v-model="me.playername"
                    :placeholder="placeholder" required @keydown.enter.prevent maxlength="20" aria-label="Indiquez votre nom"
                    :class="{'invalid' : me.avatar && !me.playername }"
                    >
                </div>
                <div class="mb-5">
                  <p class="text-center text-white font-weight-semibold">Choisissez votre avatar</p>
                  <div class="avatar-radio-button">

                    <div v-for="(n,index) in 8" :key="index">
                      <div class="mt-3 text-center">
                        <input class="d-none" type="radio" name="avatar" :id="'avatar_'+n" :value="n" v-model="me.avatar">
                        <label :for="'avatar_'+n" class="position-relative">
                          <img :src="'/images/avatars/Avatar'+n+'@2x.png'" :alt="'Avatar '+n+' jeu 7 familles Appui'" class="rounded-circle img-fluid avatar"
                            :class="{ hasavatar : me.avatar}">
                          <div class="circle rounded-circle border-success position-absolute overflow-hidden">
                            <img src="/images/pictos/ic_check.svg" class="position-absolute" alt="Picto validation avatar Appui 7 familles">
                          </div>
                        </label>
                      </div>
                      <div class="mt-3 text-center">
                        <input class="d-none" type="radio" name="avatar" :id="'avatar_'+(n+8)" :value="(n+8)" v-model="me.avatar">
                        <label :for="'avatar_'+(n+8)" class="position-relative">
                          <img :src="'/images/avatars/Avatar'+(n+8)+'@2x.png'" :alt="'Avatar '+(n+8)+' jeu 7 familles Appui'" class="rounded-circle img-fluid avatar"
                            :class="{ hasavatar : me.avatar}">
                          <div class="circle rounded-circle border-success position-absolute overflow-hidden">
                            <img src="/images/pictos/ic_check.svg" class="position-absolute" alt="Picto validation avatar Appui 7 familles">
                          </div>
                        </label>
                      </div>

                    </div>

                  </div>
                </div>

                <div v-if="create">
                  <button :disabled="!me.avatar || !me.playername" type="button"
                    class="btn btn-lg btn-primary btn-block" @click="formStep = 2">Continuer</button>
                </div>
                <button type="submit" v-else :disabled="!me.avatar || !me.playername"
                  class="btn btn-lg btn-primary btn-block">
                  <!-- <img src="/images/pictos/ic_user_white.png" srcset="/images/pictos/ic_user_white@2x.png 2x" alt="" class="mr-2"> -->
                  Rejoindre une partie
                </button>
              </div>
              <div v-show="formStep == 2">
                <div class="text-center">
                  <img :src="'/images/avatars/Avatar'+ me.avatar + '.png'" :srcset="'/images/avatars/Avatar'+ me.avatar + '@2x.png 2x'" class="rounded-circle avatar-big mb-4">
                </div>
                <div v-if="create" class="mb-5">
                  <p class="text-center text-white">Combien de joueurs seront de la partie?</p>
                  <div class="form-group d-flex justify-content-between square-radio-button">
                    <div v-for="(n,index) in 5" :key="index">
                      <input class="d-none" type="radio" name="playerLimit" :id="'playerLimit_'+n" :value="n + 1"
                        v-model="playerLimit">
                      <label class="btn btn-outline-light position-relative" :for="'playerLimit_'+n">
                        {{n + 1}}
                        <img src="/images/pictos/ic_check.svg" class="position-absolute">
                      </label>
                    </div>
                  </div>
                </div>

                <div v-if="create">
                  <p class="font-weight-semibold text-center text-white">Choisissez l’une des options suivantes:</p>

                  <button type="submit" :disabled="!me.avatar || !me.playername"
                    class="btn btn-primary btn-block d-flex align-items-center justify-content-center mb-3"
                    @click="$store.gameLogic.onlyBot = true">
                    <img src="/images/pictos/ic_computer.svg" class="mr-2" width="39.5px" height="31.98px">
                    Jouer contre l’ordinateur
                  </button>

                  <button type="submit" :disabled="!me.avatar || !me.playername" class="btn btn-primary btn-block">
                    <img src="/images/pictos/ic_users.svg" class="mr-2" width="32px" height="32px">
                    Jouer contre vos amis
                  </button>
                </div>


              </div>
              <!-- </transition> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"
import Header from "./Header.vue"

export default {
  name: 'LoginForm',
  components: {
    Header,
  },
  data() {
    return {
      formStep: 1,
      roomID: this.$route.query.join,
      create: this.$route.query.join ? false : true,
      placeholder: 'Quel est votre nom?',
      me: {
        avatar: '',
        playername: '',
        playerIndex: false,
      },
      playerLimit: 4,
      showModalShare: false,
      showModalWaitOthers: false,
      // players: [],
      // for debug only //
      store: this.$store,
    }
  },
  methods: {
    createRoom: function () {
      this.$store.socket.emit('room:create', {
        playername: this.me.playername,
        playerLimit: this.playerLimit
      }, roomID => {
        if (!roomID) {
          console.log('Erreur dans la création de la room')
          return false;
        }
        this.$store.roomID = roomID
        this.roomID = roomID
        this.showModalShare = true
        this.joinRoom();
      });
    },
    joinRoom: function () {
      localStorage.setItem('savedName', this.me.playername);
      localStorage.setItem('lastParty', '?join=' + this.roomID)
      this.$store.socket.emit('room:join', {
        roomID: this.roomID,
        playername: this.me.playername,
        avatar: this.me.avatar,
      }, data => {
        if (data.statut == "success") {
          this.$store.socket.sid = data.sid
          this.$emit('update:logged', true);

          this.$store.gameLogic.playerLimit = data.playerLimit
          this.$store.gameLogic.playerLayout = data.playerLayout
          this.$store.selfPlayer = data.players[this.$store.socket.sid]
          this.$store.selfPlayer.myPlayerLayout = []

          for (let i in this.$store.gameLogic.playerLayout) {
            if(this.$store.gameLogic.playerLayout[i]) {
              this.$store.selfPlayer.myPlayerLayout.push((this.$store.gameLogic.playerLayout[i] + this.$store.selfPlayer.playerIndex ) % this.$store.gameLogic.playerLimit)
            } else {
              this.$store.selfPlayer.myPlayerLayout.push(false)
            }
          }

        } else {
          this.$store.display.modalGameDeleted = true;
        }
      });
    },
  },
  created() {
    const savedName = localStorage.getItem('savedName')
    const lastParty = localStorage.getItem('lastParty')

    if (lastParty == window.location.search) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const room = urlParams.get('join')

      if (room && savedName) {
        this.me.playername = savedName
        this.joinRoom()
      }
    }
    else
      localStorage.setItem('started', false)
  },
  mounted() {
    tns({
      container: '.avatar-radio-button',
      items: 3,
      loop: false,
      controls: false,
      mouseDrag: true,
      navPosition: "bottom",
      // autoplay: true
    });
  },
  beforeUpdate(){
    if(!this.me.playername && this.me.avatar) {
      this.placeholder = "N'oubliez pas votre nom!";
    } else {
      this.placeholder = 'Quel est votre nom?';
    }
    console.log('beforeUpdate')
  }
}
</script>
