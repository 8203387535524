<template>
  <div class="position-fixed bg-white menu w-100 h-100">

    <div class="row h-100 mx-0">
      <div class="col-12 px-0">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-auto mt-3 mt-md-4 mb-md-3">
              <img src="/images/Logo_jeu_fondBlanc.png" srcset="/images/Logo_jeu_fondBlanc@2x.png 2x" alt="Logo Appui jeu des 7 familles" @click="!notStarted ? $store.display.modalLeave = true : false" width="132px" height="90px">
            </div>
            <div class="col-auto ml-auto">
              <button type="button" class="btn btn-outline-dark py-2 px-3 d-flex align-items-center" @click="$store.display.menu = false">
                <img src="/images/pictos/ic_close.svg" alt="Icone fermer menu Appui jeu 7 familles" class="mr-2" width="18px" height="18px">
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 px-0 align-self-center">
        <div class="container">

          <div class="row pb-4">
            <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto">

              <p class="font-weight-semibold text-center" v-if="!notStarted">Partie en cours...</p>

              <button type="button" @click="$store.display.menu = false" class="btn btn-sm btn-primary btn-block mb-3">
                <span v-if="!notStarted">Retourner à la partie</span>
                <span v-else>Jouer une partie</span>
              </button>

              <button @click="$store.display.rules = true" type="button" class="btn btn-sm btn-info btn-block mb-3 d-flex align-items-center justify-content-center">
                <img src="/images/pictos/ic_rules.svg" alt="Icone règles du jeu Appui 7 familles" class="mr-2" width="20.81px" height="17.97px">
                Règles du jeu
              </button>

              <button @click="$store.display.families = true" type="button" class="btn btn-sm btn-info btn-block mb-3 d-flex align-items-center justify-content-center">
                <img src="/images/pictos/ic_familles.svg" alt="Icones famille jeu Appui" class="mr-2" width="19.34px" height="17.98px">
                Tableau des familles
              </button>

              <button v-if="!notStarted" type="button" @click="$store.display.modalLeave = true" class="btn btn-sm btn-outline-primary btn-block mb-2 d-flex align-items-center justify-content-center">
                <img src="/images/pictos/ic_door.svg" alt="icone exit jeu Appui" class="mr-2">
                Quitter la partie
              </button>

            </div>
          </div>

        </div>
      </div>
      <div class="col-12 px-0 align-self-end">
        <div class="bg-primary-light w-100">
          <div class="container py-4">
            <div class="row">
              <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto">
                <p class="text-center">Si vous souhaitez contacter Info-aidant&nbsp;:</p>

                <a href="tel:1 855 852-7784" @click="triggerCall" class="btn btn-sm btn-light btn-block d-flex align-items-center justify-content-center">
                  <img src="/images/pictos/ic_phone.svg" class="mr-2" alt="icone téléphone Appui" width="18px" height="18px">
                  1 855 852-7784
                </a>
                <button type="button" class="btn btn-sm btn-light btn-block mt-3 d-flex align-items-center justify-content-center"
                  @click="$store.display.modalAppui = true">
                  <img src="/images/pictos/ic_external_link.svg" class="mr-2" alt="icone lien externe Appui.org" width="18px" height="18px">
                  lappui.org
                </button>

                <button @click="$store.display.about = true" class="btn btn-sm btn-light btn-block mt-3 mb-3 d-flex align-items-center justify-content-center">
                  <img src="/images/pictos/ic_infos.svg" class="mr-2" alt="Icone à propos jeu Appui" width="18px" height="18px">
                  À propos du projet
                </button>
                 <a target="_blank" style="display:flex;justify-content:center;" href="https://www.lappui.org/fr/actualite/Netiquette-et-confidentialite/">Politique de confidentialité</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: ['notStarted'],
  methods: {
    triggerCall() {
      this.$gtm.trackEvent({event:'telephone'})
      console.log('call');
    }
  }
}
</script>
