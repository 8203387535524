<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-white mt-5">Test page</h1>
                </div>
                <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto">
                    <div class="bg-white rounded-sm shadow p-5 mt-5">
                        <h3 class="mb-4">Génération de parties</h3>
                        <input v-model="nRooms" class="form-control mb-3">
                        <button @click="generateRooms" class="btn btn-primary btn-block mb-3">GENERATE</button>
                        <div class="alert alert-danger mb-0">
                            {{ message }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto">
                    <div class="bg-white rounded-sm shadow p-5 mt-5">
                        <h3 class="mb-4">Sound check</h3>
                        <button @click="audioPlayers[0].play()" class="btn btn-secondary text-white btn-sm btn-block mb-3">Bulle</button>
                        <button @click="audioPlayers[1].play()" class="btn btn-secondary text-white btn-sm btn-block mb-3">Draw</button>
                        <button @click="audioPlayers[2].play()" class="btn btn-secondary text-white btn-sm btn-block mb-3">Card sound</button>
                        <button @click="audioPlayers[3].play()" class="btn btn-secondary text-white btn-sm btn-block mb-3">Found card</button>
                        <button @click="audioPlayers[4].play()" class="btn btn-secondary text-white btn-sm btn-block mb-3">Famille</button>
                        <button @click="audioPlayers[5].play()" class="btn btn-secondary text-white btn-sm btn-block mb-3">Your turn</button>
                    </div>
                </div>
                <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto">
                    <div class="bg-white rounded-sm shadow p-5 mt-5">
                        <h3 class="mb-4">Sound check dans JS</h3>
                        <button @click="playAudioFiles(0)" class="btn btn-secondary text-white btn-sm btn-block mb-3">Bulle</button>
                        <button @click="playAudioFiles(1)" class="btn btn-secondary text-white btn-sm btn-block mb-3">Draw</button>
                        <button @click="playAudioFiles(2)" class="btn btn-secondary text-white btn-sm btn-block mb-3">Card sound</button>
                        <button @click="playAudioFiles(3)" class="btn btn-secondary text-white btn-sm btn-block mb-3">Found card</button>
                        <button @click="playAudioFiles(4)" class="btn btn-secondary text-white btn-sm btn-block mb-3">Famille</button>
                        <button @click="playAudioFiles(5)" class="btn btn-secondary text-white btn-sm btn-block mb-3">Your turn</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Test",
        props: ['player'],
        data() {
            return {
                nRooms: 0,
                message: '0 rooms générées',
                audioFiles: [
                    '/sounds/bulle.mp3',
                    '/sounds/draw.mp3',
                    '/sounds/card-sound.mp3',
                    '/sounds/found-card.mp3',
                    '/sounds/famille.mp3',
                    '/sounds/your-turn.mp3',
                ],
                audioPlayers : [],
            }
        },
        methods: {
            generateRooms: function () {
                this.$store.socket.emit('admin:simulation', {
                    rooms: this.nRooms
                })
                this.message = this.nRooms + " rooms générées"
                this.nRooms = 0
            },
            playAudioFiles: function(i) {
                new Audio(this.audioFiles[i]).play()
            }
        },
        mounted() {
            this.audioPlayers = this.audioFiles.map( file => {
                var audio = new Audio();
                audio.src = file;
                return audio;
            })
        }
    }
</script>

<style>

</style>
