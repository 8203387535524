<template>
  <div class="my-4 text-center row mx-0 align-items-end justify-content-center w-100">
    <div class="ml-2 mr-2 ml-md-3 ml-lg-0 mr-md-3">
      <p class="text-white small mb-2"><span v-if="player.bot">🤖 </span>{{player ? player.playername : "Bonjour!"}}</p>
      <div class="position-relative avatar-player d-inline-block playing"
        :class="{ask: ($store.gameLogic.statut == 'playerTurn' || $store.gameLogic.statut == 'playerReplay') && $store.selfPlayer.playerIndex == $store.gameLogic.actualTurn && player.cards.length > 0}" @click="askCard">

        <img :src="'/images/avatars/Avatar'+avatar+'.png'" :srcset="'/images/avatars/Avatar'+avatar+'@2x.png 2x'" alt=""
          class="rounded-circle img-fluid avatar">

        <transition name="fade">
          <div class="circle rounded-circle border-warning position-absolute overflow-hidden"
            v-show="$store.gameLogic.actualTurn == player.playerIndex">
            <transition name="fade">
              <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                style="enable-background:new 0 0 24 24;" xml:space="preserve" class="position-absolute" width="24"
                height="24" v-show="$store.gameLogic.statut == 'playerTurn' || $store.gameLogic.statut ==  'playerReplay'">
                <path fill="#ff4e00" d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z" />
                <g>
                  <circle fill="#fff" cx="6.5" cy="12.5" r="1.5" />
                  <circle fill="#fff" cx="12.5" cy="12.5" r="1.5" />
                  <circle fill="#fff" cx="18.5" cy="12.5" r="1.5" />
                </g>
              </svg>
            </transition>
          </div>
        </transition>

        <transition name="fade">
          <div class="circle-nocard rounded-circle position-absolute overflow-hidden d-flex align-items-center justify-content-center"
            v-if="player.cards.length == 0">
            <img src="/images/pictos/ic_not_allowed.svg" alt="Picto passe son tour Appui 7 familles">
          </div>
        </transition>

        <Bulles :player="player" :position="position"/>

        <transition name="growUp">
          <div class="score position-absolute" v-show="player.score > 0">
            <div class="tooltip fade bs-tooltip-top position-absolute">
              <div class="arrow"></div>
              <div class="tooltip-inner small py-2">Nombre de familles complétées</div>
            </div>
            <p class="text-white bg-danger border border-white rounded px-1 mb-0">
              {{ player.score }}
            </p>
          </div>
        </transition>
      </div>
    </div>
    <!-- <div class="hand d-flex justify-content-between mt-2 mt-md-3"> -->
    <transition-group name="slideDown" tag="div" class="hand d-flex mt-2 mt-md-3">
      <div class="hand-card-wrapper position-relative" v-for="card in player.cards.slice(0,5).length" :key="card">
        <div class="hand-card border-white position-absolute d-flex align-items-center justify-content-center">
          <p v-if="card == player.cards.slice(0,5).length && player.cards.length > 5" class="mb-0 font-weight-semibold text-white">
            +{{ player.cards.length - 5 }}
          </p>
          <img v-else src="/images/Logo_versoCarte.png" srcset="/images/Logo_versoCarte@2x.png 2x" alt="" class="p-2 p-xl-3 img-fluid-all">
        </div>
      </div>
    </transition-group>
    <!-- </div> -->
  </div>
</template>

<script>
import Bulles from './Bulles.vue'

export default {
  name: "Player",
  props: ['player','position'],
  components: {
    Bulles,
  },
  data() {
    return {
      avatar : this.player ? this.player.avatar : "",

    }
  },
  methods: {
    askCard() {
      if( this.player.cards.length > 0 &&
          this.$store.selfPlayer.playerIndex == this.$store.gameLogic.actualTurn &&
          (this.$store.gameLogic.statut == 'playerTurn' || this.$store.gameLogic.statut == 'playerReplay' ) ) {
        this.$store.gameLogic.statut = 'askCard';
        this.$store.gameLogic.askToPlayer = this.player;
      }
    },
  }
}
</script>
