<template>
  <div class="my-4 text-center">
    <p class="text-white small mb-1"><span v-if="player && player.bot">🤖 </span>{{ player ? player.playername : "Bonjour!" }}</p>
    <div class="position-relative avatar-player d-inline-block" :class="{ 'ready': player || $store.gameLogic.onlyBot}">
      <img :src="'/images/avatars/Avatar'+ player.avatar +'.png'"
           :srcset="'/images/avatars/Avatar'+ player.avatar + '@2x.png 2x'"
           class="rounded-circle img-fluid avatar"
           v-if='player && player.avatar'>
      <img src="/images/avatars/Avatar.png"
           srcset="/images/avatars/Avatar@2x.png 2x"
           class="rounded-circle img-fluid avatar"
           v-else>
      <div class="circle rounded-circle border-success position-absolute overflow-hidden">
        <img src="/images/pictos/ic_check.svg" class="position-absolute">
      </div>
    </div>
  </div>
</template>

<script>
  // import io from 'socket.io-client'

export default {
  name: "WaitingPlayer",
  props: ['player'],
}
</script>
