import Vue from 'vue'
import VueGtm from 'vue-gtm';
import VueRouter from "vue-router";
import Store from "./stores/dataStore.js";
import Deck from "./stores/deckStore.js";
import io from 'socket.io-client'
import App from './App.vue'
import "./listeners"
import Login from "./components/Login.vue";
import Game from "./components/Game.vue";
import Test from "./components/Test.vue";
import VueSocialSharing from 'vue-social-sharing'
import './registerServiceWorker'

Vue.use(VueRouter);
Vue.use(VueSocialSharing);
Vue.config.productionTip = false
Vue.prototype.$store = Store; // rend l'utilisation du store globale ($store)
Vue.prototype.$deck = Deck;
Vue.prototype.$store.socket = io(process.env.VUE_APP_IP_SOCKET,{secure: process.env.VUE_APP_SECURE});

const router = new VueRouter({
	mode: "history",
	base: __dirname,
	routes: [
		{
			path: "/",
			component: Login,
			name: "Login",
			meta: {
				title: "L'Appui",
			},
		},
		{
			path: "/game/:room",
			component: Game,
			name: "Game",
			meta: {
				title: "Partie en cours...",
			},
		},
		{
			path: "/test",
			component: Test,
			name: "Test",
			meta: {
				title: "TEST PAGE",
			},
		},
	],
});

Vue.use(VueGtm, {
  id: 'GTM-MD692R9', //or ['GTM-xxxxxx', 'GTM-yyyyyy'], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview:'env-4',
  //   gtm_cookies_win:'x'
  // },
  // defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  // enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

export const vm = new Vue({
	router,
	render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
	document.title = to.meta.title + " | L'Appui - Jeu des 7 familles";
	console.log(to)
	next();
});

