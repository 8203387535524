<template>
  <div class="text-center row mx-0 align-items-end justify-content-center w-100">
    <div class="ml-2 mr-2 ml-md-3 mr-md-3">
      <div class="position-relative avatar-player tuto-avatar-player d-inline-block playing">

        <img :src="'/images/avatars/Avatar'+avatar+'.png'" :srcset="'/images/avatars/Avatar'+avatar+'@2x.png 2x'" alt=""
          class="rounded-circle img-fluid avatar">

        <transition name="fade">
          <div v-if="play" class="circle rounded-circle border-warning position-absolute overflow-hidden">
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
              style="enable-background:new 0 0 24 24;" xml:space="preserve" class="position-absolute" width="24"
              height="24">
              <path fill="#ff4e00" d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z" />
              <g>
                <circle fill="#fff" cx="6.5" cy="12.5" r="1.5" />
                <circle fill="#fff" cx="12.5" cy="12.5" r="1.5" />
                <circle fill="#fff" cx="18.5" cy="12.5" r="1.5" />
              </g>
            </svg>
          </div>
        </transition>

        <!-- // Bulles -->
        <div>
          <transition name="growUp">
            <div class="bulle bg-white rounded-circle position-absolute" :class="position" v-if="card">
              <img src="/images/pictos/ic_check.svg" alt="" width="40px">
            </div>
          </transition>

          <transition name="growUp">
            <div class="bulle bg-white rounded-circle position-absolute" :class="position" v-if="draw">
              <img src="/images/pictos/ic_cancel.svg">
            </div>
          </transition>

          <transition name="growUp">
            <div class="bulle-square position-absolute" :class="position" v-if="ask">
              <div class="box-square-content bg-white rounded p-3 d-flex align-items-center justify-content-between" :class="'float-'+position">
                <img class="rounded-circle img-fluid avatar" :src="'/images/avatars/Avatar'+ toAvatar +'.png'" :srcset="'/images/avatars/Avatar'+ toAvatar +'@2x.png 2x'">
                <!-- {{ $store.gameLogic.action.toPlayerID }} -->
                <p class="ml-2 mb-0 p-1 text-white h2 rounded" :style="'width:46px;background-color:'+$deck[cardId].color">{{ $deck[cardId].family[0] }}</p>
                <p class="ml-2 mb-0 font-weight-semibold">{{ $deck[cardId].name }} ?</p>
              </div>
            </div>
          </transition>
        </div>

        <!-- <Bulles :player="player" :position="position"/> -->

      </div>
    </div>
    <!-- <div class="hand d-flex mt-2 mt-md-3">
      <div class="hand-card-wrapper position-relative">
        <div class="hand-card border-white position-absolute d-flex align-items-center justify-content-center"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import Bulles from './Bulles.vue'

export default {
  name: "TutoPlayer",
  props: ['play',"avatar",'ask','askTo','card','draw','position'],
  components: {
    // Bulles,
  },
  data() {
    return {
      toAvatar: this.askTo,
      cardId : Math.floor(Math.random() * Math.floor(41)) + 1
    }
  },
  beforeUpdate() {
    this.toAvatar = this.askTo
    this.cardId = Math.floor(Math.random() * Math.floor(41)) + 1
    console.log('CARDID',this.cardId)
  }
}
</script>
