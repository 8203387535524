<template>
  <div>
    <div class="row align-items-center">
      <div class="col-auto mt-3 mt-md-4 mb-md-3">
        <img src="/images/Logo_jeu_fondFonce.png" width="132px" height="90px" srcset="/images/Logo_jeu_fondFonce@2x.png 2x" alt="Logo Appui jeu des 7 familles" @click="!notStarted ? $store.display.modalLeave = true : false">
      </div>
      <div class="col-auto ml-auto">
        <button type="button" class="btn btn-light py-2 px-3 d-flex align-items-center" @click="$store.display.menu = true">
          <img src="/images/pictos/ic_menu.svg" class="mr-2" alt="Icone menu jeu Appui 7 familles" width="22px" height="18px">
          Menu
        </button>
      </div>
    </div>
    <transition name="fade">
      <Menu v-show="$store.display.menu" :notStarted="notStarted"/>
    </transition>
  </div>
</template>

<script>
import Menu from './Menu.vue'
export default {
  name: 'Header',
  props: ['notStarted'],
  components: {
    Menu,
  },
}
</script>
